import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchNewSetting = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-clone", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_CLONE_SETTING_DATA", payload: res.data })
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, fetch: false });
        })
}
export const onSaveCloneProfile = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-clone-setting", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, save: false });
        })
}


export const onCreateTopic = (data, fetchData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-custom-topic", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, save: false });
        })
}


export const onCreateInstance = (data, fetchData, handleClose, setName, setLoader) => (dispatch, getState) => {
    commonAxios("create-instance", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                setName("")
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false);
        })
}

export const onFetchTotal = (data, setMessageLength) => (dispatch, getState) => {
    commonAxios("fetch-clone-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMessageLength(res.data)
            }
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCheckCustomDomain = (data, setIsValidDomain, loader, setLoader) => (dispatch, getState) => {
    commonAxios("check-domain", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setIsValidDomain("valid")
            } else {
                setIsValidDomain("invalid")
            }
            setLoader({
                ...loader,
                check: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                check: false
            })
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
        })
}


// export const onCheckCustomDomain = (name, data, state, setState, type, setLoader, navigate) => (dispatch, getState) => {
//     commonAxios("check-business-url", { url: data.url, type: name }, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status === true) {
//                 if (name === "domain") {
//                     if (setLoader) {
//                         setLoader(false)
//                     }
//                     setState({ ...state, domainCheck: res.status, domainMsg: res.msg, domainBtnType: "Add this domain" });
//                 } else {
//                     setState({
//                         ...state,
//                         checkBusinessUrl: res.status,
//                         checkBusinessMessage: res.msg,
//                     });
//                     // if (type === "submit") {
//                     //     dispatch(onCreateBusiness("create-business", data, setLoader, navigate));
//                     // } else {
//                     //     setLoader(false)
//                     // }
//                 }
//             } else {
//                 if (setLoader) {
//                     setLoader(false)
//                 }
//                 if (name === "domain") {
//                     setState({ ...state, domainCheck: res.status, domainMsg: res.msg, domainBtnType: "Check" });
//                 } else {
//                     setState({
//                         ...state,
//                         checkBusinessUrl: res.status,
//                         checkBusinessMessage: res.msg,
//                     });
//                 }
//             }
//         })
//         .catch((err) => {
//             console.log(err)
//             if (setLoader) {
//                 setLoader(false)
//             }
//         });
// };

export const onCreateProduct = (data, loader, setLoader, fetchProducts, handleClose, setState) => (dispatch, getState) => {
    commonAxios("create-product", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchProducts();
                handleClose();
                setState(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
        })
}

export const onUpdateProduct = (data, loader, setLoader, fetchProducts, handleClose, setState) => (dispatch, getState) => {
    commonAxios("update-product", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchProducts();
                handleClose();
                setState(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
        })
}

export const onGenerateCharacter = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-own-character", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setState({
                    ...state,
                    aiImage: res.data
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, submit: false })
        })
}

export const onAcceptImage = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-clone-character", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (setState) {
                    setState(data.url)
                }
            } else {
                setState("")
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, accept: false })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, accept: false })
        })
}


// no axios call
export const onChangeCloneSettingProfile = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_CLONE_SETTING_PROFILE", payload: { name, value } });
}
export const onChangeCloneSettingBio = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_CLONE_SETTING_BIO", payload: { name, value } });
}

export const onUpdateSetting = (name, value) => (dispatch) => {
    dispatch({ type: "HANDLE_UPDATE_SETTING", payload: { name, value } });
}

export const onUpdateCustomWarning = (name, value) => (dispatch) => {
    dispatch({ type: "HANDLE_UPDATE_CUSTOM_WARNING", payload: { name, value } });
}

export const onUpdateCustomWarningToggle = (name, check) => (dispatch) => {
    dispatch({ type: "HANDLE_UPDATE_CUSTOM_WARNING_TOGGLE", payload: { name, check } });
}

export const onChangeMessage = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_MESSAGE", payload: { name, value } })
}

export const onChangeMonetization = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_MONETIZATION", payload: { name, value } })
}

export const onChangeNotification = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "CHANGE_CLONE_NOTIFICATION", payload: data })
}

export const onChangeNotificationToggle = (name, check) => (dispatch) => {
    let data = { name, check }
    dispatch({ type: "CHANGE_CLONE_NOTIFICATION_TOGGLE", payload: data })
}

// export const onChangeLanguage = (name, value) => (dispatch) => {
//     dispatch({type : "ON_CHANGE_MESSAGE", payload : {name, value}})
// }