import React, { useEffect, useState } from 'react'
import useAdd from '../../../../../src/assets/images/SocialIcons/useAdd.png';
import text from '../../../../../src/assets/images/SocialIcons/text_a.png';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { onFetchTotal } from '../../../../Redux/Actions/CloneSettingAction';
import { useDispatch } from 'react-redux';

const Usage = ({ cId }) => {

    const dispatch = useDispatch()
    const totalLength = 10000
    const [messageLength, setMessageLength] = useState(0)
    const [trainLength, setTrainLength] = useState(0)


    const fetchTotal = () => {
        let data = {
            cId: cId
        }
        dispatch(onFetchTotal(data, setMessageLength))
    }

    useEffect(() => {
        fetchTotal()
    }, [])

    return (
        <>
            <div className="studio_setting ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Usage</h2>
                        </div>
                        <div>
                            <div className="Usage_wrap mt-4">
                                <p className='f-18' style={{ color: "#18181b", fontWeight: "500" }}><i class="fa-solid fa-comment-dots me-2" style={{ color: "#ff6a27" }}></i>Messages</p>

                                <div className='mt-4'>
                                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow={Math.round((messageLength / totalLength) * 100)} aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar" style={{ width: Math.round((messageLength / totalLength) * 100) + "%" }}></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-1 '>
                                        <p className='f-13' style={{ color: "#71717a" }}><b className='text-dark' >
                                            {messageLength}/10,000</b> SMS were used during this billing cycle.</p>
                                        <p className='f-13' style={{ color: "#71717a", fontWeight: "500" }}>Resets in 5 days</p>
                                    </div>

                                </div>

                            </div>
                            <div className='Usage_wrap_bottom'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex  align-items-center'>
                                        <span><img src={useAdd} alt="" /></span>
                                        <p className='f-13 ms-3' style={{ color: "#71717a", fontWeight: "500" }}><span className='text-dark'>Want more messages?</span><br />

                                            Add more messages to your plan</p>
                                    </div>
                                    <Link to={`/clone-nav?cId=${cId}&type=analytics`} className='theme-btn me-0 f-13'><span>Add <FaPlus style={{ fontSize: "13px" }} /></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* Calling Minutes */}

                        {/* end div */}

                        {/* Training Words */}
                        <div>
                            <div className="Usage_wrap mt-5">
                                <p className='f-18' style={{ color: "#18181b", fontWeight: "500" }}> <img src={text} alt className='me-2' /> Training Words</p>

                                <div className='mt-4'>
                                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow={Math.round((trainLength / totalLength) * 100)} aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar" style={{ width: Math.round((trainLength / totalLength) * 100) + "%" }}></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-1 '>
                                        <p className='f-13' style={{ color: "#71717a" }}><b className='text-dark' >{trainLength} / 10,000</b> messages used this billing period</p>
                                        <p className='f-13' style={{ color: "#71717a", fontWeight: "500" }}>Resets in 5 days</p>
                                    </div>

                                </div>

                            </div>
                            <div className='Usage_wrap_bottom'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex  align-items-center'>
                                        <span><img src={useAdd} alt="" /></span>
                                        <p className='f-13 ms-3' style={{ color: "#71717a", fontWeight: "500" }}><span className='text-dark'>Want more messages?</span><br />
                                            Add more messages to your plan</p>
                                    </div>
                                    <Link to={`/clone-nav?cId=${cId}&type=train`} className='theme-btn me-0 f-13'><span>Add <FaPlus style={{ fontSize: "13px" }} /></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default Usage