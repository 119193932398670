import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { Modal } from "react-bootstrap"
import uploads from "../../../../../assets/images/uploads.png"
import { themeColor } from '../../../../../Global/Global'
import { onUploadMedia } from '../../../../../Redux/Actions/CommonActions'
import Swal from "sweetalert2"
import { useDispatch } from 'react-redux'
import Recording from './Recording'

const VoiceTrainModal = (props) => {
    const dispatch = useDispatch()
    let allowedExt = ['audio/mpeg'];
    const [percent, setPercent] = useState(0)
    const [activeUploadType, setActiveUploadType] = useState("voiceover")
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false
    })

    const onInputFile = (e, type) => {
        setActiveUploadType(type)
        let allowedSize = 5000000
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < allowedSize) {
                    const formData = new FormData()
                    formData.append('upload_type', type)
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, props.fetchUploadVoice, loader, setLoader, setPercent, false, props.handleClose))

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for Music File is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }




    return (
        <Modal className="theme-modal ai_img_wrap voice-modal ds" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="vidClose" >
                    <div style={{ position: "relative" }}>
                        <div className='modalClose' onClick={props.handleClose} style={{ top: "-52px", right: "-16px" }}><AiOutlineClose style={{

                        }} /></div>
                    </div>

                </div>
                <div >
                    <div className="">
                        <h2>Upload Voice Sample</h2>
                    </div>

                </div>
                <div className='upload_wrap mt-3'>
                    <p style={{ color: "#52525b", fontWeight: "600" }}>Upload</p>
                    <div className='upload_content_wrap'>
                        <img src={uploads} alt="" />
                        <input
                            type="file"
                            onChange={(e) => onInputFile(e, "voiceover")}
                        />
                        {loader.upload && activeUploadType === "voiceover" ?
                            <>
                                <div class="progress" style={{ width: "100%" }}>
                                    <div class="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: themeColor }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{percent}%</div>
                                </div>
                            </> :
                            <>
                                <h3 >Upload File</h3>
                                <p className="text-p3 text-center"><span className="text-brand">Click to browse</span> or drag and drop</p>
                            </>}
                    </div>

                    <div className="col-12 mt-3">
                        <div className="dividline">
                            <span>or</span>
                        </div>
                    </div>


                    <div>
                        <p style={{ color: "#52525b", fontWeight: "600" }}>Record</p>
                        <Recording
                            onInputFile={onInputFile}
                            uploadLoader={loader}
                            activeUploadType={activeUploadType}
                        />
                        {/* <p style={{ color: "#a1a1aa", fontWeight: "500", fontSize: "14px", marginTop: "10px" }}>Record to view a recommended script to read</p> */}
                    </div>



                    <div className='text-end mt-2'>
                        <button className='theme-btn me-3 text-p4' onClick={props.handleClose}><span>Cancel</span></button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default VoiceTrainModal