import React from 'react'
import { Nav, Button, Tab } from 'react-bootstrap';
import Activity from './Activity';
import Monetization from './Monetization';
import Sources from './Sources';
import Popular from './Popular';
const Analytics = (props) => {

    const { userData, conversationData, messageData } = props;

    return (
        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Analytics</h2>

                </div>
                <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div className="col-xl-6 col-12 ">
                        <Nav variant="pills" className="welcome-studio" style={{ justifyContent: "start" }}>
                            <Nav.Item>
                                <Nav.Link eventKey="first">Activity</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Popular</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link eventKey="third">Sources</Nav.Link>
                            </Nav.Item> */}

                            {/* <Nav.Item>
                                <Nav.Link eventKey="forth">Monetization</Nav.Link>
                            </Nav.Item> */}

                        </Nav>
                    </div>
                    <div className="col-12">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Activity
                                    userData={userData}
                                    conversationData={conversationData}
                                    messageData={messageData}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Popular />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="third">
                                <Sources />
                            </Tab.Pane> */}
                            {/* <Tab.Pane eventKey="forth">
                                <Monetization />
                            </Tab.Pane> */}

                        </Tab.Content>
                    </div>

                </Tab.Container>
            </div>

        </>
    )
}

export default Analytics
