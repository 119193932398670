import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { connectSocial } from '../../../Redux/Actions/SocialActions';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';

const ConnectModal = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const [appDetails, setAppDetails] = useState({
        user_id: auth.user.id,
        app_name: props.type,
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setAppDetails({
            ...appDetails,
            [name]: value
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(connectSocial(appDetails, props.name, props.handleClose, setLoader))
    }



    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <form onSubmit={onFormSubmit}>
                    <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                    <div className="connect-modal">
                        <h2>Connect {props.name}</h2>
                        <div className="input-wrap">
                            <label htmlFor="">Email</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp"
                                    type="email"
                                    placeholder="Enter Email"
                                    name='email'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="">Password</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp"
                                    type="password"
                                    placeholder="Enter Password"
                                    onChange={handleChange}
                                    name='password'
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="group-btn">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn full bdr" style={{ cursor: "pointer" }} type='button' onClick={props.handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" type='submit' style={{ cursor: "pointer" }}><span>{loader ? <>Connecting <FaSpinner className="spin" /></> : "Connect"}</span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal