import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch, useSelector } from "react-redux";
import { deleteSocialAccounts, saveFacebook } from "../../../../../Redux/Actions/SocialActions";
import { FaFacebookMessenger, FaWhatsapp } from "react-icons/fa"
import GroupsModal from "./GroupsModal";
import axios from "axios";
import SweetAlert from "../../../../CommonComponents/SweetAlert";

const ConnectWhatsapp = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    // group code 
    const [groups, setGroups] = useState([]);

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const responseFacebook = async (response) => {

        if (props.isGroup) {
            const data = {
                user_id: auth.user.id,
                title: response.name,
                token: response.accessToken,
                fb_id: response.userID,
                // category: selection.type,
                // email: details.email,
            };

            dispatch(saveFacebook(data));
        } else {
            try {
                const res = await axios.get(
                    `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
                );
                setDetails({
                    ...details,
                    name: response.name,
                    userId: response.userID,
                    openPopup: true,
                    buttonText: "Connect",
                    accessToken: response.accessToken,
                    pages: res.data.data,
                });
            } catch (err) {
                console.log(err);
            }

            /** Fetch FB Groups */
            try {
                const res = await axios.get(
                    " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
                );

                setGroups(res.data.data);
            } catch (err) {
                console.log(err);
            }
        }

    }

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const deleteIntegration = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: sweet.id,
            user_id: auth.user.id
        }
        dispatch(deleteSocialAccounts("delete-integration", data, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    return (
        <>
            <FacebookLogin
                appId="780210132546000" //APP ID NOT CREATED YET
                fields="name,email"
                scope="pages_manage_posts,publish_to_groups,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                callback={(e) => responseFacebook(e)}
                render={renderProps => (

                    <div className='band_wrapper mt-4'>
                        <div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="img_wrap">
                                            {props.type === "whatsapp" ?
                                                <FaWhatsapp className='int_icon' />
                                                :
                                                <FaFacebookMessenger className='int_icon' />
                                            }
                                        </div>
                                        <div className="details ps-3">
                                            <h3 className="name">{props.type === "whatsapp" ? "WhatsApp Integration" : "Facebook Messenger Integration"}</h3>
                                            <p className='para_text2 f-13'>{props.type === "whatsapp" ? "Add your clone to WhatsApp" : "Add your clone to Facebook Messenger"}</p>
                                        </div>

                                    </div>
                                </div>
                                <div >
                                    <div className="dis-btn-wrap ">
                                        <button className='theme-btn' style={{ width: '100px', fontWeight: 500, fontSize: "13px", padding: "6px 10px" }} onClick={renderProps.onClick}><span>Connect</span> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            props.data?.map((item) => {
                                return (
                                    <>
                                        <hr style={{ opacity: "1", color: "#a1a1aa45", marginTop: "5px" }} />
                                        <div className='text-start d-flex align-items-center justify-content-between  '>
                                            <div>
                                                <p className="connectTitle p-0" style={{ fontWeight: 600, fontSize: '13px', color: '#000', marginBottom: '1px' }}>{props.name}</p>
                                                <p className="connectInfo m-0 p-0" style={{ fontWeight: 400, fontSize: '13px' }}>{item?.username} - Added on {item.created}</p>
                                            </div>
                                            <div className="dis-btn-wrap ">
                                                <button className='theme-btn' style={{ width: '100px', fontWeight: 500, fontSize: "13px", padding: "6px 10px" }} onClick={() => deleteIntegration(item.id)}><span>Disconnect</span> </button>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>

                )}
            />
            <GroupsModal
                details={details}
                setDetails={setDetails}
                groups={groups}
            />

            <SweetAlert
                show={sweet.enable}
                message={`<b>Are you sure?</b> <br> <br> Do you want to delete this ${props.type} account?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </>
    )
}

export default ConnectWhatsapp;