import React, { useState } from "react";
import { BiConversation, BiTrash, BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";
import Conversations from "./Conversations";
import { useEffect } from "react";
import { fetchConversation, fetchMessages, onClearConversation, onCreateConv, onCreateMessage, onFetchMessages, onGetCloneDetails, onSetMessage } from "../../../Redux/Actions/ChatAction";
import { useDispatch } from "react-redux";
import SweetAlertTwo from "./SweetAlertTwo";
import Swal from "sweetalert2";
import MessageBox from "./MessageBox";
import Header from "../../Header/Header";
import TitleBar from "../../CommonComponents/TitleBar";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { AiOutlineMessage, AiTwotoneMessage } from "react-icons/ai";
import { themeColor } from "../../../Global/Global";
// import vooduuIcon from "../../../assets/images/logo.png"

const Chat = () => {

    const location = useLocation()
    const { cId } = queryString.parse(location.search)

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const chat = useSelector(state => state.chat.data)
    const [selectedCid, setSelectedCid] = useState(0)
    const [cloneDetails, setCloneDetails] = useState([])
    const [typingActive, setTypingActive] = useState(false)
    const [activeBlink, setActiveBlink] = useState(false)
    const [showIntTyping, setShowIntTyping] = useState(false)
    const [loader, setLoader] = useState({
        fetch: false,
        createConv: false,
        message: false,
        detailsLoader: false
    })

    const [info, setInfo] = useState({
        id: -1,
        enable: false,
    })

    const [state, setState] = useState({
        userId: auth.user.id,
        id: selectedCid,
        cId: cId,
        message: "",
        type: "user",
    })


    const handleCreateConv = () => {
        setLoader({
            ...loader,
            createConv: true
        })
        let data = {
            userId: auth.user.id,
            id: cId
        }
        dispatch(onCreateConv(data, fetchConv, setSelectedCid, setActiveBlink, loader, setLoader))
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const handlePerform = (Swal) => {
        let data = {
            cId: cId
        }
        setLoader({
            ...loader,
            clear: true
        })
        dispatch(onClearConversation(data, fetchMessages, Swal))
        setInfo({
            ...info,
            id: -1,
            enable: false,
        })
    }

    const fetchConv = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data = {
            cId: cId
        }
        dispatch(fetchConversation(data, selectedCid, setSelectedCid, handleCreateConv, loader, setLoader))
    }

    const handleChange = (e) => {
        setState({
            ...state,
            message: e.target.value
        })
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.isShiftKey) {
            handleSubmit(e)
        }
    }
    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault()
        }
        if (state.message !== "") {
            let obj = {
                message: state.message,
                type: state.type,
            }

            dispatch(onSetMessage(obj))
            let scrollDiv = document.getElementById("chat-data-box")
            setTimeout(() => {
                if (scrollDiv.clientHeight < scrollDiv.scrollHeight) {
                    scrollDiv.scrollBy(0, scrollDiv.scrollHeight)
                }
            }, 1000)

            setTimeout(() => {
                setTypingActive(true)
            }, 3000)

            dispatch(onCreateMessage(state, setTypingActive, setActiveBlink))
            setState({
                ...state,
                message: "",
            })
        }
    }

    const handleClearConv = () => {
        if (selectedCid) {
            setInfo({
                ...info,
                enable: true
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select conversation to clear it!',
                confirmButtonColor: themeColor
            })
        }
    }

    const fetchMessages = () => {
        setLoader({
            ...loader,
            message: true
        })
        let data = {
            id: selectedCid
        }
        dispatch(onFetchMessages(data, loader, setLoader))
    }

    useEffect(() => {
        if (selectedCid) {
            setState({
                ...state,
                id: selectedCid
            })
            fetchMessages()
        }
    }, [selectedCid])

    const fetchCloneDetails = () => {
        let data = {
            id: cId,
            userId: auth.user.id
        }
        dispatch(onGetCloneDetails(data, setCloneDetails, loader, setLoader))
    }

    useEffect(() => {
        fetchConv()
    }, [])

    useEffect(() => {
        fetchCloneDetails()
    }, [cId])

    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])

    return (
        <>
            {/* <Header /> */}

            {/* <TitleBar title="Dashborad" /> */}
            <section className="chat-wrap pt-0">
                <div className="chat-left">
                    <div className="chat_profile">
                        {loader.detailsLoader ?
                            <i className='fa fa-spin fa-spinner' />
                            :
                            <span className="chat_pro_img mb-2">

                                <img src={cloneDetails.image} alt="" />
                            </span>
                        }
                        {loader.detailsLoader ?
                            <i className='fa fa-spin fa-spinner' /> :
                            <span className="chat_pro_name mb-3 text-capitalize" style={{ fontSize: 15, fontWeight: "bold" }}>
                                {cloneDetails.name}
                            </span>
                        }
                        {/* <span className="chat_pro_category mb-3  text-capitalize" style={{ fontSize: 13 }}>
                            Youtube Expert
                        </span> */}

                    </div>
                    <div className="new-chat">
                        {+auth.user.isClient === 1 ? "" :
                            <button className="theme-btn full mt-0" onClick={handleCreateConv}>
                                <span>
                                    <BiConversation />
                                    {loader.createConv ? <>Creating <i className="fa fa-spinner fa-spin mx-1" /> </> :
                                        "New Conversation"}
                                </span>
                            </button>}
                    </div>

                    <div className="chat-scroll">
                        <div className="chat-list">
                            {chat.conversations.length > 0 ?
                                chat.conversations.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Conversations
                                                curElem={curElem}
                                                fetchConv={fetchConv}
                                                setSelectedCid={setSelectedCid}
                                                selectedCid={selectedCid}
                                                firstId={chat.conversations[0].id}
                                                handlePerform={handlePerform}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                :
                                <div className="text-center mt-2" style={{ color: "#000" }}>
                                    {
                                        loader.fetch ?
                                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} />
                                            : "No conversation available"}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="clear-conv">
                        {+auth.user.isClient === 1 ? "" :
                            <button onClick={() => handleClearConv()} className=" full theme-btn"><BiTrash /> <span> Clear conversation</span></button>
                        }
                    </div>
                </div>

                <div className="chat-right">

                    <div className="chat-area" id="chat-data-box">
                        <div className="chat-area-main" >
                            {
                                loader.message ?
                                    <div style={{ display: "flex", height: `100%`, justifyContent: "center", alignItems: "center" }}>
                                        <div className="loader">
                                        </div>
                                    </div>
                                    :
                                    chat.message.length > 0 ?
                                        chat.message.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <MessageBox
                                                        curElem={curElem}
                                                        index={index}
                                                        setTypingActive={setTypingActive}
                                                        setActiveBlink={setActiveBlink}
                                                        activeBlink={activeBlink}
                                                        typingActive={typingActive}
                                                        length={chat.message.length}
                                                        showIntTyping={showIntTyping}
                                                        setShowIntTyping={setShowIntTyping}
                                                        userImage={auth.user.profile}
                                                        cloneDetails={cloneDetails}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : ""
                            }

                        </div>
                    </div>
                    {+auth.user.isClient === 1 ? "" :
                        <div className="chat-area-bottom">
                            <div className="chat-area-bottom-wrap">
                                <div className="chat-bottom-bar">
                                </div>

                                <form className="chat-inp" onSubmit={handleSubmit}>
                                    <span className="chat-icon"><AiOutlineMessage style={{ color: themeColor, fontSize: "35px" }} /></span>
                                    <textarea
                                        type="text"
                                        value={state.message}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        className="chat_typing_box"
                                        rows={+(state.message.length / 100) > 1 ? +(state.message.length / 100) > 4 ? 4 : +(state.message.length / 100) : 1}
                                        required
                                    />
                                    <button
                                        type="submit"
                                        className="chat-send"
                                        style={{ background: "none", outline: "none", border: "none" }}
                                    >
                                        <img src={require('../../../assets/images/chat-send.png')} style={{ filter: 'hue-rotate(53deg)' }} />
                                    </button>
                                </form>
                                <div className="Chat_disclaimer text-center" >
                                    <p className="mb-0" style={{ fontSize: 12, fontWeight: "600" }} >AiDuals  may display inaccurate or offensive information about people, places, or facts that doesn’t represent VineaSX's views.</p>
                                    <p style={{ fontSize: 12, fontWeight: "600" }}>Response can take upto 5-10 minutes.</p>
                                </div>

                            </div>
                        </div>}
                </div>

            </section>

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor={themeColor}
                cancelButtonColor={themeColor}
                confirmButtonText='Yes, clear it!'
                confirmTitle='Clearing...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={handlePerform}
                onCancel={handleCancel}
            />

        </>
    )
}

export default Chat;
