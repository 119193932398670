import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { onCreateInstance } from '../../Redux/Actions/CloneSettingAction'
import { IoIosArrowRoundBack } from "react-icons/io";

const EmbedPopup = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    // const handleCopy = () => {
    //     const copyText = document.getElementById("myEmbedScript");
    //     copyText.select();
    //     copyText.setSelectionRange(0, 99999);
    //     navigator.clipboard.writeText(copyText.value);
    // }


    const handleSubmit = () => {
        if (props.type === "copy") {
            const copyText = document.getElementById("myEmbedScript");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
        } else {
            let data = {
                cId: props.cId,
                name: props.name,
                embedCode: props.script
            }
            setLoader(true)
            dispatch(onCreateInstance(data, props.fetchInstances, props.handleClose, props.setName, setLoader))
        }
    }

    const handleBack = () => {
        props.handleShow()
        props.handleClose()
    }


    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>

                <div className="modalClose" onClick={props.handleClose}><BsXLg style={{ position: "absolute", top: -38, right: -15, fontSize: 20 }} /></div>
                <h4>Your clone embed code</h4>
                <p className="pt-2" style={{ fontSize: 13 }}>Place the script above the closing {'</body>'} tag on the page where you want the widget to be displayed.</p>
                <div className="input-outer mt-4 position-relative">
                    <textarea
                        className="common-inp alt"
                        readOnly
                        value={props.script}
                        id='myEmbedScript'
                        rows={4}
                    />

                </div>
                <div className='text-end'>
                    {props.type === "copy" ? null :
                        <button className="theme-btn bdr mt-4 me-2" onClick={handleBack}><span><IoIosArrowRoundBack size={20} /> Back</span></button>
                    }
                    <button className="theme-btn  mt-4" onClick={handleSubmit}><span>{props.type === "copy" ? "Copy Embed" : loader ? <>Submitting <i className='fa fa-spinner fa-spin' /></> : "Submit"}</span></button>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EmbedPopup