import React, { useEffect, useState } from 'react'
import CloneHome from './CloneHome'
import { Nav, Tab, Button } from 'react-bootstrap';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import TitleBar from '../../CommonComponents/TitleBar';

import { FiHome, FiUsers } from "react-icons/fi";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { IoPieChartOutline } from "react-icons/io5";
import { BiMoneyWithdraw } from "react-icons/bi";
import CloneTrain from './CloneTrain/CloneTrain';
import Audience from './Audience/Audience';
import Analytics from './Analytics/Analytics';
import MonetizationMain from './Monetization/MonetizationMain';

import queryString from "query-string"
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onFetchCloneRedux, onFetchCloneStudio, onFetchConversation, onFetchMessage, onFetchUser, unmountClone } from '../../../Redux/Actions/CloneActions';
import { useSelector } from 'react-redux';
import { onFetchNewSetting } from '../../../Redux/Actions/CloneSettingAction';
import { FaUserAstronaut } from "react-icons/fa";
import Characters from './Characters/Characters';


const CloneNav = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const { cId, type } = queryString.parse(location.search)
    const clone = useSelector(state => state.clone)
    const [activeTab, setActiveTab] = useState("home")
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [userLoader, setUserLoader] = useState({
        fetch: true
    })
    const [conversationLoader, setConversationLoader] = useState({
        fetch: true
    })
    const [messageLoader, setMessageLoader] = useState({
        fetch: true
    })

    const [userData, setUserData] = useState([])
    const [conversationData, setConversationData] = useState([])
    const [messageData, setMessageData] = useState([])


    const fetchClone = () => {
        let data = {
            cId
        }
        dispatch(onFetchCloneRedux(data, loader, setLoader))
    }

    const fetchStudio = () => {
        let data = {
            cId
        }
        dispatch(onFetchCloneStudio(data, loader, setLoader))
    }

    const fetchNewSetting = () => {
        let data = {
            cId: cId
        }
        dispatch(onFetchNewSetting(data, loader, setLoader))
    }

    const fetchUser = () => {
        setUserLoader({
            ...userLoader,
            fetch: true
        });
        let data = {
            cId: cId
        }
        dispatch(onFetchUser(data, setUserData, userLoader, setUserLoader))
    }
    const fetchConversation = () => {
        setConversationLoader({
            ...conversationLoader,
            fetch: true
        });
        let data = {
            cId: cId
        }
        dispatch(onFetchConversation(data, setConversationData, conversationLoader, setConversationLoader))
    }

    const fetchMessage = () => {
        setMessageLoader({
            ...messageLoader,
            fetch: true
        });
        let data = {
            cId: cId
        }
        dispatch(onFetchMessage(data, setMessageData, messageLoader, setMessageLoader))
    }


    useEffect(() => {
        if (cId) {
            fetchClone()
            fetchStudio()
            fetchNewSetting();
            fetchUser()
            fetchConversation()
            fetchMessage()

            return () => {
                dispatch(unmountClone())
            }
        }

    }, [cId])


    useEffect(() => {
        if (type) {
            setActiveTab(type || "home")
        }
    }, [type, location])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <Header />
                <TitleBar title="Training" />
                <div className="site-wrap" style={{ overflow: "auto" }}>
                    <div className="container-fluid">
                        <div className='studio-wrap'>
                            <Tab.Container id="left-tabs-example">
                                <div className="row">
                                    <div className="col-xl-2 col-md-3 col-12">
                                        <div className='studio-nav'>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item onClick={() => setActiveTab("home")}>
                                                    <Nav.Link className={activeTab === "home" ? "active" : ""}><FiHome /> Home</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => setActiveTab("train")}>
                                                    <Nav.Link className={activeTab === "train" ? "active" : ""}><MdOutlineFilterCenterFocus /> Train</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => setActiveTab("char")}>
                                                    <Nav.Link className={activeTab === "char" ? "active" : ""}><FaUserAstronaut /> Characters</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => setActiveTab("audience")}>
                                                    <Nav.Link className={activeTab === "audience" ? "active" : ""}><FiUsers /> Audience</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => setActiveTab("analytics")}>
                                                    <Nav.Link className={activeTab === "analytics" ? "active" : ""}><IoPieChartOutline /> Analytics</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item onClick={() => setActiveTab("monetization")}>
                                                    <Nav.Link className={activeTab === "monetization" ? "active" : ""}><BiMoneyWithdraw /> Monetization</Nav.Link>
                                                </Nav.Item>
                                            </Nav>

                                        </div>

                                    </div>
                                    <div className="col-xl-10 col-lg-9 col-md-9 mt-md-0 mt-5 col-12">
                                        <Tab.Content>
                                            <Tab.Pane className={activeTab === "home" ? 'active show' : ""}>
                                                <CloneHome
                                                    clone={clone}
                                                    userData={userData}
                                                    messageData={messageData}
                                                />

                                            </Tab.Pane>
                                            <Tab.Pane className={activeTab === "train" ? 'active show' : ""}>
                                                <CloneTrain
                                                    clone={clone}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane className={activeTab === "char" ? 'active show' : ""}>
                                                <Characters
                                                    clone={clone}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane className={activeTab === "audience" ? 'active show' : ""}>
                                                <Audience />
                                            </Tab.Pane>
                                            <Tab.Pane className={activeTab === "analytics" ? 'active show' : ""}>
                                                <Analytics
                                                    userData={userData}
                                                    conversationData={conversationData}
                                                    messageData={messageData}
                                                />
                                            </Tab.Pane>

                                            <Tab.Pane className={activeTab === "monetization" ? 'active show' : ""}>
                                                <MonetizationMain />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container >
                        </div >
                    </div >
                </div >

                <Footer />
            </>
    )
}

export default CloneNav
