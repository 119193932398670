import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDateModal = ({ show, handleClose, customDate, setCustomDate }) => {

    const [loader, setLoader] = useState({
        submit: false
    });

    const handleChange = (e, name) => {
        const selectedDate = new Date(e);
        let date = selectedDate.toLocaleDateString('en-GB');
        setCustomDate({ ...customDate, [name]: date });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, submit: true });
        handleClose();
    }

    return (
        <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
            <Modal.Body className="p-0">
                <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                <div className="feed_warp mb-3" style={{ marginTop: "10px" }}>
                    <div className="container">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feed_mod_head">
                                        <h2 className='mb-2'>Choose custom date</h2>
                                        <div className="d-flex">
                                            <div className="input-outer w-100">
                                                <div>From</div>
                                                <DatePicker
                                                    className='common-inp'
                                                    dateFormat='dd/MM/yyyy'
                                                    placeholder='From'
                                                    name='fromDate'
                                                    value={customDate.fromDate}
                                                    onChange={(e) => handleChange(e, "fromDate")}
                                                />
                                            </div>

                                            <div className="input-outer w-100">
                                                <div>To</div>
                                                <DatePicker
                                                    className='common-inp'
                                                    dateFormat='dd/MM/yyyy'
                                                    placeholder='To'
                                                    name='toDate'
                                                    value={customDate.toDate}
                                                    onChange={(e) => handleChange(e, "toDate")}
                                                    minDate={new Date(`${customDate.fromDate.split("/")[2]}-${customDate.fromDate.split("/")[1]}-${customDate.fromDate.split("/")[0]}`)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="feed_btn text-end">
                                        <button type='submit' disabled={loader.save} className='theme-btn me-3 text-p4 mt-3'><span>{loader.save ? <>Submitting<i className='fa fa-spin fa-spinner' /></> : "Submit"}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Modal.Body>
        </Modal>
    )
}

export default CustomDateModal
