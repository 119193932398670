import React from 'react'
import { BiLoaderCircle } from 'react-icons/bi'
import { IoFilterSharp } from 'react-icons/io5'

const ContentFilter = (props) => {

    const handleChangeFilter = (e) => {
        if (e.target.checked) {
            props.setFilterType((draft) => [...draft, e.target.value])
        } else {
            let newArr = props.filterType.filter((curElem) => {
                return curElem !== e.target.value
            })
            props.setFilterType(newArr)
        }
    }

    const handleChangeStatus = (e) => {
        if (e.target.checked) {
            props.setFilterType((draft) => [...draft, e.target.value])
        } else {
            let newArr = props.filterType.filter((curElem) => {
                return curElem !== e.target.value
            })
            props.setFilterType(newArr)
        }
    }

    return (
        <div className="train_filter" style={{ position: "relative" }}>

            <div className="theme-btn" onClick={props.handleToggle} style={{ cursor: "pointer" }}>
                <span>Filter <IoFilterSharp className="ms-2" /></span>
            </div>

            {props.show ?
                <div className='relposition'>

                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="flase" aria-controls="collapseOne">
                                    Content type
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    {props.contentNav.length > 0 ?
                                        props.contentNav.map((curElem, index) => {
                                            return (
                                                <div className="form-check" key={index}>
                                                    <label className="form-check-label" >
                                                        {curElem.name}
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={curElem.name}
                                                        checked={props.filterType.includes(curElem.name)}
                                                        onChange={handleChangeStatus}
                                                    />
                                                </div>
                                            )
                                        })
                                        : ""}
                                </div>
                            </div>
                        </div>
                        {/* <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Status
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <BiLoaderCircle size={16} color="#FFA500" className='me-1' /> Queued
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <div className="d-flex align-items-center">
                                                <div className="spinner-border me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div> Processing
                                            </div>
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <div className="d-flex align-items-center ">
                                                <span className='b_cricle me-2'></span> Complete
                                            </div>
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <div className="d-flex align-items-center ">
                                                <span className='g_circle me-2'></span> Failed
                                            </div>
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <div className="d-flex align-items-center">
                                                <div className="spinner-border r me-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                Deleting
                                            </div>
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Memory type
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            By Me
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            My Others
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className='p-3'>
                                <div className="d-flex justify-content-center ">
                                    <button className='theme-btn me-3'><span>Reset</span></button>
                                    <button className='theme-btn'><span>Apply</span></button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>


                : ""

            }
        </div>
    )
}

export default ContentFilter