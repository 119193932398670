import React from 'react'
import TitleBar from '../../../CommonComponents/TitleBar'
import Header from '../../../Header/Header'
import Footer from '../../../Footer/Footer'
// import Navbar from "../Navbar";
// import Footer from "../Footer";
import SettingMenu from './SettingMenu'
import SettingContent from './SettingContent'
// import TitleBar from "../TitleBar";

const Settings = () => {
  return (
    <>
      <Header />
      <TitleBar title="settings" />
      <div className="site-wrap" style={{ paddingBottom: "0", minHeight: "100%" }}>
        <div className="site-container" style={{ maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}>
          <div className="site-container for-editor" style={{ maxWidth: "100%", padding: "0" }}>
            <div className="row">
              <div className="col-lg-4 tabLeft">
                <div className="tabCont-left">
                  <SettingMenu />
                </div>
              </div>
              <SettingContent />
            </div>
          </div>
        </div>
      </div >
      <Footer />
    </>
  )
}

export default Settings