import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const fetchSocialAccounts = () => (dispatch, getState) => {
    commonAxios("fetch-integration", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(addSocialAccounts(res.data));
            } else {
                dispatch(addSocialAccounts([]));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const saveFacebook = (data) => async (dispatch, getState) => {
    const body = JSON.stringify(data);
    commonAxios("save-facebook", body, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(fetchSocialAccounts(getState().auth.token));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const saveYoutube = (data) => async (dispatch, getState) => {
    const body = JSON.stringify(data);
    commonAxios("save-youtube", body, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(fetchSocialAccounts(getState().auth.token));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const deleteSocialAccounts = (url, data, setSweet) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false

            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false

            })
            dispatch(setAlert(err.message, 'danger'));
        })
}

export const connectSocial = (url, data, name, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(`${name} connected successfully`, 'success'))
                dispatch(fetchSocialAccounts());
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            handleClose()
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            console.log(err)
        })
}

// No Axios Here

export const addSocialAccounts = (data) => (dispatch) => {
    dispatch({ type: 'ADD_SOCIAL_ACCOUNTS', payload: data })
}