import React, { useState } from 'react'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { RxCross2, RxDragHandleDots2 } from 'react-icons/rx';
import { FaPlusCircle } from 'react-icons/fa';
import { onCreateTopic, onDeleteTopic } from '../../../../../Redux/Actions/CloneActions';
import { useDispatch } from 'react-redux';
import { themeColor } from '../../../../../Global/Global';

const Topics = (props) => {

    const { state, setState, cId, sId } = props
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(false);
    const [loader, setLoader] = useState({
        create: false,
    });


    const [sweet, setSweet] = useState({
        id: -1,
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = (e, id) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            id: id,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id,
            cId: cId,
            sId: sId,
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteTopic(data, state, setState, setSweet))
    }

    const handleAddTopic = (e) => {
        const { value } = e.target;
        if (value && isEditable === true) {
            let data = {
                cId: cId,
                sId: sId,
                topic: value
            }
            setLoader({ ...loader, create: true })
            dispatch(onCreateTopic(data, state, setState, loader, setLoader))
            setIsEditable(false)
        }
    };

    return (
        <>
            <ul id='EmailTags' className='common-inp d-flex'>
               
                <li className={`email_tag ${state.selectedTopic === -1 ? "active" : ""}`} onClick={() => { setState({ ...state, selectedTopic: -1 }) }}>
                    <span className="content"> <RxDragHandleDots2 style={{ marginRight: "6px", borderRight: "1px solid" }} />All</span>
                </li>
                {
                    state.topicArray.length > 0 ?
                        state.topicArray.map((curElem, index) => {
                            return (
                                <li className={`email_tag ${state.selectedTopic === curElem.id ? "active" : ""}`} key={index} onClick={() => { setState({ ...state, selectedTopic: curElem.id }) }}>
                                    <span className="content"> <RxDragHandleDots2 style={{ marginRight: "6px", borderRight: "1px solid" }} />{curElem.topic} <i className="fa-solid fa-trash-can ms-2" style={{ fontSize: "13px" }} onClick={(e) => onDelete(e, curElem.id)} /> </span>
                                </li>
                            )
                        })
                        : ""
                }
                {loader.create ?
                    <li style={{ display: "flex", alignItems: "center" }}>
                        <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "13px" }} />
                    </li>
                    : ""
                }
                 <li className='email_tag' >
                    <span className="content">
                        {isEditable ?
                            <>
                                <input
                                    type="text"
                                    name='content'
                                    placeholder='Enter Topic'
                                    onKeyUp={(event) =>
                                        event.key === "Enter" ? handleAddTopic(event) : null
                                    }
                                />
                                <RxCross2 onClick={() => setIsEditable(false)} />
                            </>
                            :
                            <div onClick={() => setIsEditable(true)}> <FaPlusCircle style={{ color: "#71717a", marginRight: "5px" }} />Add a topic</div>
                        }
                    </span>
                </li>
            </ul >

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure</b> <br> <br> You want to delete this topic?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </>
    )
}

export default Topics
