import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { onCreateQuestion, onUpdateQuestion } from '../../../../../Redux/Actions/CloneActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { setAlert } from '../../../../../Redux/Actions/AlertActions';

const AddQuestionModal = ({ show, handleClose, topicArray, fetchQuestions, curElem, questions, setQuestions, type }) => {

    const location = useLocation()
    const dispatch = useDispatch();
    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)

    const [questionData, setQuestionData] = useState({
        selectedTopic: -1,
        question: "",
        topics: []
    });
    const [loader, setLoader] = useState({
        save: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setQuestionData({ ...questionData, [name]: value })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true });
        if (type === "editQuestion") {
            let data = {
                id: curElem.id,
                cId: cId,
                sId: studioData.id,
                tId: questionData.selectedTopic,
                question: questionData.question
            }
            dispatch(onUpdateQuestion(data, loader, setLoader, questions, setQuestions, handleClose))
        } else {
            let data = {
                cId: cId,
                sId: studioData.id,
                tId: questionData.selectedTopic,
                question: questionData.question,
            }
            dispatch(onCreateQuestion(data, loader, setLoader, fetchQuestions, handleClose))
        }
    };

    useEffect(() => {
        if (type === "editQuestion") {
            setQuestionData({
                ...questionData,
                selectedTopic: curElem.tId,
                question: curElem.questions,
                topics: topicArray
            })
        } else {
            setQuestionData({
                ...questionData,
                selectedTopic: -1,
                question: "",
                topics: topicArray
            })
        }
    }, [topicArray, curElem, show])

    return (
        <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
            <Modal.Body className="p-0">
                <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                <div className="feed_warp mb-3" style={{ marginTop: "10px" }}>
                    <div className="container">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feed_mod_head">
                                        <h2 className='mb-2'>Add Suggested Question</h2>
                                        <p className='mt-1 mb-2 para_text'>Suggested Question</p>

                                        <div className="input-outer w-100">
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Question"
                                                name='question'
                                                value={questionData.question}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className='mb-2 mt-2 para_text'>Choose Topics</p>
                                        <ul id='EmailTags' className='common-inp d-flex'>
                                            <li className={`email_tag ${+questionData.selectedTopic === -1 ? "active" : ""}`}>
                                                <span onClick={() => setQuestionData({ ...questionData, selectedTopic: -1 })} className="content"> <RxDragHandleDots2 style={{ marginRight: "6px", borderRight: "1px solid" }} />All</span>
                                            </li>
                                            {
                                                questionData.topics.length > 0 ?
                                                    questionData.topics.map((curElem, index) => {
                                                        return (
                                                            <li key={index} className={`email_tag ${+questionData.selectedTopic === +curElem.id ? "active" : ""}`}>
                                                                <span onClick={() => setQuestionData({ ...questionData, selectedTopic: curElem.id })} className="content"> <RxDragHandleDots2 style={{ marginRight: "6px", borderRight: "1px solid" }} />{curElem.topic}</span>
                                                            </li>
                                                        )
                                                    })
                                                    : ""
                                            }
                                        </ul>
                                    </div>

                                    <div className="feed_btn text-end">
                                        <button type='submit' disabled={loader.save} className='theme-btn me-3 text-p4 mt-3'><span>{loader.save ? <>{type === "editQuestion" ? "Updating" : "Saving"} <i className='fa fa-spin fa-spinner' /></> : type === "editQuestion" ? "Update" : "Save"}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Modal.Body>
        </Modal >
    )
}

export default AddQuestionModal
