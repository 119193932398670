import React, { useEffect, useState } from 'react';
import { Nav, Tab, Button } from 'react-bootstrap';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import TitleBar from '../../CommonComponents/TitleBar';

import Modal from 'react-bootstrap/Modal';

import { BsArrowRight, BsArrowLeft, BsCoin, BsPcDisplayHorizontal, BsHeartPulse, BsMortarboard, BsRocket, BsPalette, BsBinoculars, BsMegaphone, BsShieldPlus, BsMusicPlayer, BsTrophy, BsBriefcase, BsBox, BsPaintBucket, BsController, BsLayers, BsTv, BsTags, BsBook, BsPlus } from "react-icons/bs";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { MdModeEditOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { onCreateClone, onFetchClone, onUpdateClone } from '../../../Redux/Actions/CloneActions';
import Swal from 'sweetalert2';
import { themeColor } from '../../../Global/Global';
import { onUploadMedia } from '../../../Redux/Actions/CommonActions';
import Topics from './Topics';
import Purpose from './Purpose';
import Style from './Style';
import queryString from "query-string"
import { useImmer } from 'use-immer';
import FinalCloneBox from './FinalCloneBox';
import { setAlert } from '../../../Redux/Actions/AlertActions';

const CreateClone = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { cId } = queryString.parse(location.search)

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const [show, setShow] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    const [percent, setPercent] = useState(0);

    const [loader, setLoader] = useState({
        submit: false,
        upload: false,
        create: false,
        fetch: false
    })
    const [img, setImg] = useState("https://backend.aiduals.com/assets/images/def.png")
    const [data, setData] = useImmer({
        name: "",
        headLine: "",
        description: "",
        image: "",
        topics: [],
        purpose: [],
        creativity: 60,
        responseLength: 30,
        imagePrompt: "",
        imageResponse: ""

    })

    const handleShow = () => setShow(true);

    const handleNext = () => {
        setActiveTab(activeTab + 1);
    };

    const handlePrev = () => {
        setActiveTab(activeTab - 1);
    };


    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const fetchImgMedia = (url) => {
        setImg(url)
    }

    const onImageChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchImgMedia, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (cId) {
            handleUpdate()
        }
        else {
            let obj = { ...data }
            obj.image = img
            setLoader({
                ...loader,
                submit: true
            })
            dispatch(onCreateClone(obj, handleNext, navigate, loader, setLoader))
        }
    }

    const handleUpdate = (type) => {
        let obj = { ...data, cId }
        if (type === "final") {
            obj.type = type
        }
        if (type === 1 && obj.topics.length === 0) {
            dispatch(setAlert("Please select topic to continue!", "danger"))
        } else if (type === 2 && obj.purpose.length === 0) {
            dispatch(setAlert("Please select goal for your clone to continue!", "danger"))
        } else {
            setLoader({
                ...loader,
                submit: true
            })
            delete obj.id
            obj.image = img
            dispatch(onUpdateClone(obj, handleNext, handleShow, loader, setLoader))
        }

    }


    const fetchClone = () => {
        let data = {
            cId
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchClone(data, setData, setImg, loader, setLoader))
    }



    useEffect(() => {
        if (cId) {
            fetchClone()
        }
    }, [cId])


    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div> :
            <>
                <Header />
                <TitleBar title="Training" />
                <div className="site-wrap">
                    <div className="site-container">

                        <div className='clone-cont'>
                            <div className='clone-cont-top'>
                                <div className='navigate'>
                                    {activeTab > 1 && (
                                        <span onClick={handlePrev}><BsArrowLeft /> Previous</span>
                                    )}
                                    {/* {activeTab < 5 && activeTab > 1 && (
                                    <span onClick={handleNext}>Next <BsArrowRight /></span>
                                )} */}
                                </div>
                            </div>
                            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                                <div className='clone-steps'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={1}>
                                            <form onSubmit={handleSubmit}>
                                                <h3>Create a Clone</h3>
                                                <p>Create an identical copy of your profile or identity.</p>
                                                <div className='clone-img block' style={{ position: "relative" }}>
                                                    <div className='clone-img-holder'>
                                                        <img src={img} alt="" />
                                                        {loader.upload ?
                                                            <div className='edit_loader'>
                                                                <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: 20 }} />
                                                            </div>
                                                            :
                                                            <div className='clone-img-up'>
                                                                <MdModeEditOutline style={{ color: "#fff", cursor: "pointer" }} />
                                                                <input
                                                                    type="file"
                                                                    onChange={(e) => onImageChange(e)}
                                                                />
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className='input-wrap'>
                                                    <label className='label' htmlFor="">Name</label>
                                                    <div className='input-outer'>
                                                        <input
                                                            className='common-inp alt'
                                                            type="text"
                                                            placeholder='Give a name to your clone'
                                                            name='name'
                                                            value={data.name}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-wrap'>
                                                    <label className='label' htmlFor="">Headline</label>
                                                    <div className='input-outer'>
                                                        <input
                                                            className='common-inp alt'
                                                            type="text"
                                                            placeholder='Give a headline to your clone'
                                                            name='headLine'
                                                            value={data.headLine}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-wrap'>
                                                    <label className='label' htmlFor="">Description</label>
                                                    <div className='input-outer'>
                                                        <textarea
                                                            className='common-inp alt'
                                                            rows="5"
                                                            placeholder='Write a description to your clone'
                                                            name='description'
                                                            value={data.description}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='input-wrap'>
                                                    <button type='submit' className='theme-btn full' ><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
                                                </div>
                                            </form>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={2}>
                                            <Topics
                                                handleUpdate={handleUpdate}
                                                data={data}
                                                setData={setData}
                                                loader={loader}
                                                setLoader={setLoader}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={3}>
                                            <Purpose
                                                handleUpdate={handleUpdate}
                                                data={data}
                                                setData={setData}
                                                loader={loader}
                                            />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey={4}>
                                            <Style
                                                handleUpdate={handleUpdate}
                                                data={data}
                                                setData={setData}
                                                loader={loader}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={5}>
                                            <FinalCloneBox
                                                handleUpdate={handleUpdate}
                                                data={data}
                                                setData={setData}
                                                img={img}
                                                setImg={setImg}
                                                onImageChange={onImageChange}
                                                loader={loader}
                                            />

                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                                <div className='clone-cont-bottom'>
                                    <Nav variant="n" >
                                        <Nav.Item><Nav.Link eventKey={1}></Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey={2}></Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey={3}></Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey={4}></Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey={5}></Nav.Link></Nav.Item>
                                    </Nav>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>

                <Footer />


                <Modal className='theme-modal video-modal ds' show={show} centered>
                    <Modal.Body className="p-0">
                        {/* <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div> */}
                        <div className="infoTxt text-center p-5">
                            <h5>Your clone has been <br /> created successfully.</h5>
                            <Link className='theme-btn mt-4' to={'/clone-studio'}><span>Next</span></Link>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
    )

}

export default CreateClone;