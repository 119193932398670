import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaRegUser } from "react-icons/fa"
import { FiLock, FiUserPlus } from "react-icons/fi"
import { useSelector } from "react-redux";


const AccountNav = () => {
  const auth = useSelector(state => state.auth)
  const [memberShip, setMemberShip] = useState([])


  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split('__'))
    }
  }, [auth.user])

  return (
    <>
      <ul>
        <li><NavLink to="/my-account"><FaRegUser /> My Account</NavLink></li>
        {+auth.user.isClientAccount === 1 || +auth.user.isVirtualAccount === 1 ? "" :
          <>
            <li><NavLink to="/privacy"><FiLock /> Privacy</NavLink></li>
            {memberShip.includes("enterprise") ||
              memberShip.includes("fastpass") ||
              memberShip.includes("bundle") ?
              <li><NavLink to="/account-management"><FiUserPlus /> Account Management</NavLink></li> : null}
          </>}
      </ul>
    </>
  )
}

export default AccountNav;