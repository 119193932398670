import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { useSelector } from 'react-redux';
import CustomDateModal from './CustomDateModal';

const Activity = (props) => {

    const { userData, conversationData, messageData } = props;

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [selectData, setSelectData] = useState({
        userFilter: "users",
        dateFilter: "all"
    });
    const [customDate, setCustomDate] = useState({
        fromDate: "",
        toDate: ""
    });
    const [generatedDate, setGeneratedDate] = useState([])
    const cloneDOB = useSelector((state) => state.clone.data.created)

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value === "custom") {
            handleShow();
        }
        setSelectData({ ...selectData, [name]: value })
    }

    useEffect(() => {
        if (cloneDOB) {
            const dates = [];
            let startDate;
            let endDate = new Date();

            const today = new Date();
            const cloneDate = new Date(cloneDOB.split(" ")[0]);

            switch (selectData.dateFilter) {
                case "last7Days":
                    startDate = new Date(today.setDate(today.getDate() - 7));
                    break;
                case "lastMonth":
                    startDate = new Date(today.setMonth(today.getMonth() - 1));
                    break;
                case "last6Months":
                    startDate = new Date(today.setMonth(today.getMonth() - 6));
                    break;
                case "custom":
                    const fromParts = customDate.fromDate.split("/");
                    startDate = new Date(`${fromParts[2]}-${fromParts[1]}-${fromParts[0]}`);
                    const toParts = customDate.toDate.split("/");
                    endDate = new Date(`${toParts[2]}-${toParts[1]}-${toParts[0]}`);
                    break;
                default:
                    startDate = cloneDate;
                    break;
            }

            if (startDate <= endDate) {
                while (startDate <= endDate) {
                    dates.push(startDate.toISOString().split('T')[0]);
                    startDate.setDate(startDate.getDate() + 1); // Move to the next day
                }
            }

            const list = dates.map((date) => {
                const count = selectData.userFilter === "users"
                    ? userData.filter(user => user.created.split(" ")[0] === date).length :
                    selectData.userFilter === "messages" ?
                        messageData.filter(conversation => conversation.created.split(" ")[0] === date).length
                        : conversationData.filter(conversation => conversation.created.split(" ")[0] === date).length;

                return {
                    name: date,
                    users: count,
                    conversations: count,
                    messages: count,
                    // pv: 1000,
                    // amt: 1000,
                };
            });

            setGeneratedDate(list);
        }
    }, [cloneDOB, selectData.userFilter, selectData.dateFilter, customDate.toDate, customDate.fromDate]);

    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="train_flex" >
                                <div className="review-box mt-3 w-100" >
                                    <div className="input-outer w-100">
                                        <div className="d-flex justify-content-between flex-wrap w-100">
                                            <div>
                                                <select
                                                    className="common-inp alt"
                                                    placeholder='Messages'
                                                    style={{ width: "200px", fontSize: "17px" }}
                                                    value={selectData.userFilter}
                                                    name='userFilter'
                                                    onChange={handleChange}
                                                >
                                                    <option value="messages">Messages</option>
                                                    <option value="conversations">Conversations</option>
                                                    <option value="users">Users</option>
                                                </select>
                                            </div>
                                            <div className='d-flex align-items-center mt-md-0 mt-3'>
                                                <select
                                                    className="common-inp alt"
                                                    placeholder='Last 7 Days'
                                                    style={{ width: "200px", fontSize: "17px" }}
                                                    name="dateFilter"
                                                    value={selectData.dateFilter}
                                                    onChange={handleChange}
                                                >
                                                    <option value="all">All Time</option>
                                                    <option value="last7Days">Last 7 Days</option>
                                                    <option value="lastMonth">Last Month</option>
                                                    <option value="last6Months">Last 6 Month</option>
                                                    <option value="custom">Custom</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="d-flex align-items-end">
                                <span style={{ color: "#000", fontSize: ' 34px', marginRight: '12px' }}>{selectData.userFilter === "users" ? userData.length : selectData.userFilter === "messages" ? messageData.length : conversationData.length}</span>
                                <p style={{ color: "#ff6a27", fontSize: "17px", paddingBottom: '7px' }}>{selectData.userFilter === "users" ? "Users" : selectData.userFilter === "messages" ? "Messages exchanged" : 'Conversations'}</p>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div style={{ width: "100%" }}>
                                <LineChart width={900} height={400} data={generatedDate} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey={selectData.userFilter} stroke="#ff6a27" />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                </LineChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomDateModal
                show={show}
                handleClose={handleClose}
                customDate={customDate}
                setCustomDate={setCustomDate}
            />
        </>
    )
}

export default Activity
