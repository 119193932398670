import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CloneCards from '../Pages/CloneStudio/CloneCards'
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';

const CreateVideo = () => {
    const dispatch = useDispatch()

    const [cloneData, setCloneData] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchClones = () => {
        dispatch(fetchData("fetch-all-clone", {}, setCloneData, loader, setLoader))
    }

    useEffect(() => {
        fetchClones()
    }, [])

    return (
        <div className="created-by">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="">Recent <span style={{ color: "#ff6a27" }}>Clones</span> </h2>
                <Link to="/clone-studio" className="proView" style={{ color: "#ff6a27" }}>View all <span><i class="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
            </div>

            <div className="row">
                {cloneData.length > 0 ?
                    cloneData.slice(0, 4).map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <CloneCards
                                    curElem={curElem}
                                    cloneData={cloneData}
                                    setCloneData={setCloneData}
                                    type="dashboard"
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className='text-center mt-5'>
                        {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                            <>
                                <p>You do not have any clone yet.</p>
                                <Link to={"/create-clone"} className='theme-btn mt-2'><span>Create Clone</span></Link>
                            </>
                        }
                    </div>
                }
            </div >
        </div >
    )
}

export default CreateVideo