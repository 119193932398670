import React, { useEffect, useState } from 'react'
import { FaPlus, FaSpinner } from 'react-icons/fa';
import { FaCommentDots } from "react-icons/fa6";
import { fetchData } from '../../../../../Redux/Actions/CommonActions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import ToneRows from './ToneRows';
import { Modal } from 'react-bootstrap';
import { onCreateToneForAll, onSubmitAddTone } from '../../../../../Redux/Actions/CloneActions';

const ToneTrain = ({ activeFetch }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { cId } = queryString.parse(location.search)

    const [toneData, setToneData] = useState([])
    const [data, setData] = useState({
        name: ""
    })
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState({
        fetch: true,
        add: false,
        all: false
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const fetchTone = () => {
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-tone", data, setToneData, loader, setLoader))
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...data }
        obj.cId = cId
        setLoader({
            ...loader,
            add: true
        })
        dispatch(onSubmitAddTone(obj, fetchTone, handleClose, setData, loader, setLoader))
    }


    const createForAll = () => {
        let data = {
            cId: cId
        }
        setLoader({
            ...loader,
            all: true
        })
        dispatch(onCreateToneForAll(data, fetchTone, loader, setLoader))
    }

    useEffect(() => {
        fetchTone()
    }, [activeFetch])


    return (
        <>
            <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12">
                            <div className="train_content_head row ">
                                <div className='col-xxl-8 col-lg-7 col-12'>
                                    <h2>Tone</h2>
                                    <p className='mt-1 para_text' >The way your clone speaks and behaves. After tone extraction, a condensed version of your tone will be shown here.</p>

                                </div>
                                <div className='col-xxl-4 col-lg-5 col-12 text-xxl-end text-center mt-xxl-0 mt-xl-3 mt-3'>
                                    <button className='theme-btn me-3 text-p4 bdr' onClick={handleShow} ><span>Add Tone</span></button>
                                    <button className='theme-btn me-3 text-p4 ' onClick={createForAll} ><span>{loader.all ? <>Extracting <i className='fa fa-spin fa-spinner' /></> : " Extract Tone from Content"}</span></button>

                                </div>
                            </div>
                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                        </div>
                        <div className="train_table  mt-4">
                            <div className="table-area">
                                <div className="table-responsive">
                                    <table className="table widget-table">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Tone</th>
                                            <th>Action</th>
                                        </tr>

                                        {toneData.length > 0 ?
                                            toneData.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ToneRows
                                                            curElem={curElem}
                                                            fetchTone={fetchTone}
                                                            toneData={toneData}
                                                            setToneData={setToneData}
                                                            index={index}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={3} className='text-center'>
                                                    {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                                                        <p className='text-center'>There is no tone available.</p>
                                                    }
                                                </td>
                                            </tr>
                                        }

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal className="theme-modal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="connect-modal">
                        <h4>Add Tone</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Tone Name</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder='Enter Tone Name Here'
                                            value={data.tone}
                                            onChange={(e) => setData({
                                                ...data,
                                                name: e.target.value
                                            })}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="group-btn">
                            <div className="row">
                                <div className="col-6">
                                    <button className="theme-btn full" type='submit'><span>{loader.add ? <>Adding <FaSpinner className="spin" /></> : "Add"}</span></button>
                                </div>
                                <div className="col-6">
                                    <button className="theme-btn full bdr" type='button' onClick={handleClose}><span>Cancel</span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default ToneTrain

