import React, { useState } from 'react'

const Style = ({ handleUpdate, data, setData, loader }) => {

    const handleChange = (e) => {
        const { name, value } = e.target
        setData((draft) => {
            draft[name] = value
        })
    }

    const onSetDefault = (name, value) => {
        let obj = {
            target: {
                name: name,
                value: value
            }
        }
        handleChange(obj)
    }


    return (
        <div>
            <h3>Set a Style for a Clone</h3>
            <p>Entails giving your clone a distinct and defined style or manner. </p>

            <div className='clone-style'>
                <div className='clone-style-single'>
                    <h5>Creativity</h5>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                    <div className='clone-style-txt mt-3'>
                        <span className='default' onClick={() => onSetDefault("creativity", 60)}><strong>Default</strong></span>
                        <p style={{ fontWeight: "600", textAlign: "end" }}>{data.creativity}</p>

                    </div>

                    <div className='inp_radio_wrap' style={{ maxWidth: "576px" }}>

                        <input
                            type="range"
                            className="mt-2"
                            min="0"
                            max="100"
                            id="customRange2"
                            name='creativity'
                            value={data.creativity}
                            onChange={handleChange}
                        />
                        {/* <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>Messages before login required is set to <span style={{ color: "#ff6a27" }}>10</span>. You can update it <span style={{ color: "#ff6a27" }}>here</span> </p> */}
                    </div>
                    <div className='clone-style-txt mt-2'>
                        <span><small>Low Creativity / High Success</small></span>
                        <span><small>High Creativity / Low Success</small></span>
                    </div>
                </div>

                <div className='clone-style-single'>
                    <h5>Response Length</h5>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                    <div className='clone-style-txt mt-3'>
                        <span className='default' onClick={() => onSetDefault("responseLength", 30)}><strong>Default</strong></span>
                        <p style={{ fontWeight: "600", textAlign: "end" }}>{data.responseLength}</p>

                    </div>
                    <div className='inp_radio_wrap' style={{ maxWidth: "576px" }}>

                        <input
                            type="range"
                            className="mt-2"
                            min="0"
                            max="100"
                            id="customRange"
                            name='responseLength'
                            value={data.responseLength}
                            onChange={handleChange}
                        />
                        {/* <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>Messages before login required is set to <span style={{ color: "#ff6a27" }}>10</span>. You can update it <span style={{ color: "#ff6a27" }}>here</span> </p> */}
                    </div>
                    <div className='clone-style-txt mt-2'>
                        <span><small>Short & Concise</small></span>
                        <span><small>Long & Thorough </small></span>
                    </div>
                </div>

            </div>

            <div className='input-wrap'>
                <button className='theme-btn full' onClick={() => handleUpdate(null)}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
            </div>
        </div>
    )
}

export default Style