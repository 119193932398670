import React, { useState } from 'react'
import ConnectModal from './ConnectModal'
import { FaDiscord, FaPaypal, FaSlack, FaStripeS, FaTelegram } from 'react-icons/fa'
import { SiTwilio } from 'react-icons/si'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { deleteSocialAccounts } from '../../../../../Redux/Actions/SocialActions'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { IoCodeSlash } from "react-icons/io5";

const ConnectReel = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false
    })

    const deleteIntegration = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        let data = {
            id: sweet.id,
            user_id: auth.user.id
        }
        dispatch(deleteSocialAccounts("delete-integration", data, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    return (
        <div>
            <div className='band_wrapper mt-4'>
                <div>
                    <div className='d-flex align-items-center justify-content-between pb-1'>
                        <div >
                            <div className="d-flex align-items-center ">
                                <div className="img_wrap">
                                    {props.type === "telegram" ?
                                        < FaTelegram className='int_icon' />
                                        : props.type === "twilio" ?
                                            <SiTwilio className='int_icon' />
                                            : props.type === "paypal" ?
                                                <FaPaypal className='int_icon' />
                                                : props.type === "autoresponder" ?
                                                    <IoCodeSlash className='int_icon' />
                                                    : props.type === "slack" ?
                                                        <FaSlack className='int_icon' />
                                                        : props.type === "discord" ?
                                                            <FaDiscord className='int_icon' />
                                                            :
                                                            <FaStripeS className='int_icon' />
                                    }
                                </div>
                                <div className="details ps-3">
                                    <h3 className="name">{`${props.name} Integration`}</h3>
                                    <p className='para_text2 f-13'>{`Add your clone to ${props.name}`}</p>
                                </div>
                            </div>
                        </div>
                        <div >
                            <button className='theme-btn' style={{ fontWeight: 500, fontSize: "13px", padding: "6px 10px" }} onClick={handleShow}><span>Connect</span> </button>
                        </div>
                    </div>
                    {
                        props.data?.map((item) => {
                            return (
                                <>
                                    <hr style={{ opacity: "1", color: "#a1a1aa45", marginTop: "5px" }} />
                                    <div className='text-start d-flex align-items-center justify-content-between  '>
                                        <div>
                                            <p className="connectTitle p-0" style={{ fontWeight: 600, fontSize: '13px', color: '#000', marginBottom: '1px' }}>{props.name}</p>
                                            <p className="connectInfo m-0 p-0" style={{ fontWeight: 400, fontSize: '13px' }}>{item?.username} - Added on {item.created}</p>
                                        </div>
                                        <div className="dis-btn-wrap ">
                                            <button className='theme-btn' style={{ width: '100px', fontWeight: 500, fontSize: "13px", padding: "6px 10px" }} onClick={() => deleteIntegration(item.id)}><span>Disconnect</span> </button>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
            <ConnectModal
                show={show}
                handleClose={handleClose}
                name={props.name}
                type={props.type}
            />

            <SweetAlert
                show={sweet.enable}
                message={`<b>Are you sure?</b> <br> <br> Do you want to disconnect this ${props.type} account?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </div>
    )
}

export default ConnectReel
