import React, { useEffect, useState } from 'react';
import noteImg from '../../../../../src/assets/images/note.png';
import { IoAddOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import EmbedPopup from '../../../CommonComponents/EmbedPopup';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { FaEye, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import InstancesRows from './InstancesRows';
import { SketchPicker } from 'react-color';
import rgbHex from "rgb-hex";
import { themeColor } from '../../../../Global/Global';


const CloneInstances = ({ cId }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [instances, setInstances] = useState([])
    const [animation, setAnimation] = useState([])
    const [name, setName] = useState("")
    const [animationName, setAnimationName] = useState("animate__shakeX")
    const [color, setColor] = useState({
        enable: false,
        value: themeColor
    })
    const [loader, setLoader] = useState({ fetch: false })

    let ebScript = `<script src="https://backend.aiduals.com/assets/clone_bot.js?id=${cId}&uId=${auth.user.id}&name=${name}&an=${animationName}&cl=${color.value.replace("#","")}" id="clone_bot"></script>`

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const handleSubmit = (e) => {
        e.preventDefault()
        handleShow2()
        handleClose()
        setColor({
            ...color,
            enable: false
        })
    }

    const handleColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        setColor({
            ...color,
            value: hex
        })
    }


    const fetchInstances = () => {
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-instance", data, setInstances, loader, setLoader))
    }

    const fetchAnimation = () => {
        dispatch(fetchData("fetch-animation", {}, setAnimation, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchInstances()
        fetchAnimation()
    }, [])


    return (

        <div className="settings_wrap ps-3">
            <div className="row">
                <div className="col-lg-12">
                    <div className="settings_head d-flex justify-content-between align-items-center">
                        <div className="instance_title">
                            <h2>Clone Instances</h2>
                            {/* <p className='para_text2 f-13'>Find out more about the examples here. </p> */}
                        </div>
                        <div className="btn-wrap">
                            <button className='theme-btn' onClick={handleShow}><span>Create Instance<IoAddOutline className=' addIcon' /></span>  </button>
                        </div>

                    </div>
                </div>

            </div>
            <div className="col-lg-12 p-2 mt-4">
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>S.no</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                                {instances.length > 0 ?
                                    instances.map((curlElem, index) => {
                                        return (
                                            <React.Fragment>
                                                <InstancesRows
                                                    curlElem={curlElem}
                                                    index={index}
                                                    instances={instances}
                                                    setInstances={setInstances}
                                                />
                                            </React.Fragment>

                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4} className='text-center py-5' >
                                            {loader.fetch ? <><i className='fa fa-spin fa-spinner fetch-loader' /></> : "No instances created yet!"}
                                        </td>
                                    </tr>

                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
                <Modal.Body className="p-0">
                    <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                    <div className="feed_warp p-3" style={{ marginTop: "10px" }}>
                        <div className="container">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="feed_mod_head">
                                            <h2 className='mb-3'>Create Instance</h2>
                                        </div>
                                        <div className="input-outer w-100">
                                            <label>Enter Name</label>
                                            <input
                                                type='text'
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                                placeholder='Enter Instance Name'
                                                className="common-inp alt"
                                            />

                                        </div>
                                        <div className="input-outer w-100 my-2">
                                            <label>Select Animation</label>
                                            <select
                                                type='text'
                                                value={animationName}
                                                onChange={(e) => setAnimationName(e.target.value)}
                                                required
                                                className="common-inp alt"
                                            >
                                                <option value={""}>Select Animation</option>
                                                {animation.length > 0 ?
                                                    animation.map((curElem, index) => {
                                                        return (
                                                            <option key={index} value={curElem.name}>{curElem.name.replace("animate__", "")}</option>
                                                        )
                                                    })
                                                    : ""}

                                            </select>

                                        </div>
                                        <div className="input-outer w-100 mt-3">
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <label>Select Color</label>
                                                <div
                                                    className="colorValue"
                                                    id="t-color-btn"
                                                    style={{ background: color.value ? color.value : themeColor, cursor:"pointer" }}
                                                    onClick={() => setColor({
                                                        ...color,
                                                        enable: true
                                                    })}
                                                ></div>
                                            </div>
                                            {color.enable ?
                                                <>
                                                    <div className="swatch-cover"
                                                        onClick={() => setColor({
                                                            ...color,
                                                            enable: false
                                                        })}
                                                    />
                                                    <SketchPicker
                                                        color={color.value}
                                                        onChange={(e) => handleColor(e)}
                                                        className="color-picker-style"
                                                    />
                                                </>

                                                : null}

                                        </div>

                                        <div className="feed_btn text-end">
                                            <button type='submit' className='theme-btn text-p4 mt-3'><span>Create</span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                </Modal.Body>
            </Modal >


            <EmbedPopup
                script={ebScript}
                handleClose={handleClose2}
                handleShow={handleShow}
                fetchInstances={fetchInstances}
                show={show2}
                name={name}
                cId={cId}
                setName={setName}
            />

        </div>




    )
}

export default CloneInstances
