const initialState = {
    whatsapp: [],
    facebook: [],
    telegram: [],
    twilio: [],
    stripe: [],
    autoresponder: []
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return {
                ...state,
                whatsapp: action.payload.whatsapp,
                facebook: action.payload.facebook,
                telegram: action.payload.telegram,
                twilio: action.payload.twilio,
                stripe: action.payload.stripe,
                autoresponder: action.payload.autoresponder ? action.payload.autoresponder : []
            }

        // case 'ADD_SYVID':
        //     return {
        //         ...state,
        //         syvid: action.payload
        //     }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}