import React, { useEffect, useState } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";
import Wabinar from "./Wabinar";
import TitleBar from "../CommonComponents/TitleBar";
import CreateVideo from "./CreateVideo";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

const Dashboard = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showClose, setShowClose] = useState(false)

    const [showPopup, setShowPopup] = useState(false);
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: '-32px',
        right: "0px",
        fontSize: 11,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: "8px 7px",
        borderRadius: 2,
    }
    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }
    const handlePlayPause = () => {
        setShow(true)
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            // setShowPopup(true)
        }
    }, []);

    return (
        <>

            <div className="dash-header">
                <Header />
            </div>

            <TitleBar title="Dashborad" />
            <div className="site-wrap for-dashboard pt-0">
                <div className='hero-banner '>
                    <div className='hero-banner-video'>
                        <iframe src="https://player.vimeo.com/video/881619324?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe>

                    </div>
                    <div className='container'>

                        <div className="welcome" style={{ position: "relative", zIndex: "2" }}>
                            <div className="welcome-top">
                                <h1>WELCOME TO {rebrand ? rebrand.name : <><span>AI</span><span className="green">DUALS</span></>}</h1>
                                <p>Dive into the world of advanced AI cloning. Your unique digital twin comes to life, capable of thinking, speaking, and sounding just like you. Begin by uploading your videos, podcasts, PDFs, blog posts, and more to create an AI that mirrors your distinct tone and style. Start shaping a truly authentic digital identity now!</p>
                                {/* <p>Create beautifully animated videos in seconds. See how {rebrand ? rebrand.name : appName}  uses its powerful AI to convert any  URL to a profitable animated video in any niche.</p> */}
                            </div>


                        </div>
                    </div>

                    <div className="welcome-video">
                        <div className="welcome-video-in">

                            <div className="welcome-video-img"><img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></div>
                            <div className="play-btn" onClick={handlePlayPause}>

                                <img
                                    id="play-button"
                                    alt=""
                                    src={require("../../assets/images/play.png")}
                                    onClick={handlePlayPause}
                                />
                            </div>

                            <Modal className="theme-modal ai_img_wrap video-modal ds" show={showPopup} onHide={handelClose2} centered>
                                <Modal.Body>
                                    <div onClick={() => setShowClose(true)} className="vidClose" >
                                        <div style={{ position: "relative" }}>
                                            <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{
                                                fontSize: "30px", marginBottom: "3px", top: '-72px',
                                                right: '-27px', position: "absolute"
                                            }} /></div>
                                            {showClose ?
                                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                                        </div>

                                    </div>
                                    <div className="modalVid">
                                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                                        <div className="para_ai h-100">
                                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                                <p style={{
                                                    fontSize: "55px",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    lineHeight: "1.3",
                                                    color: "#fff"
                                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                                            </a>
                                        </div>
                                    </div>

                                </Modal.Body>
                            </Modal>
                          
                            <Modal className="theme-modal video-modal desktop_modal " show={show} onHide={handleClose} centered>
                                <Modal.Body>
                                    <div className='modalClose ds text-end' onClick={handleClose} style={{ top: "-60px" }}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "-55px", }} /></div>
                                    <div className="modalVidN" >
                                        <iframe src="https://player.vimeo.com/video/881969178?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="430" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="ClipsReelAI Walkthrough"></iframe>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal className="theme-modal video-modal desktop_modal_bs " show={show} onHide={handleClose} centered>
                                <Modal.Body>
                                    <div className='modalClose text-end' onClick={handleClose} style={{ top: "-60px" }}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "-55px" }} /></div>
                                    <div className="modalVidN" >
                                        <iframe src="https://player.vimeo.com/video/881969178?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="100%" height="768" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="ClipsReelAI Walkthrough"></iframe>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
                {/* {+auth.user.isClientAccount === 1 ? "" : */}
                    <CreateVideo />
                    {/* } */}

                <div className="image-block">

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>Personalized AI Twin Creation:</h5>
                                    <p>Upload your digital materials such as videos, podcasts, and written content. {rebrand ? rebrand.name : appName} then utilizes this to construct an AI clone that not only reflects your thoughts and speech patterns but also captures your unique personality and style.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-1.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>Authentic Interaction Capabilities:</h5>
                                    <p>Your AI twin is designed to converse, think, and even sound exactly like you, providing an authentic and engaging interaction experience. This advanced feature allows for a more relatable and genuine digital presence, perfect for various applications.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-2.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>Comprehensive Content Integration</h5>
                                    <p>With {rebrand ? rebrand.name : appName}, integrating different types of content is effortless. Whether it’s audio files, PDF documents, or blog posts, the platform's sophisticated technology seamlessly merges these into your AI clone, enhancing the richness and depth of its interactions.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-3.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>Advanced Cloning Technology:</h5>
                                    <p>{rebrand ? rebrand.name : appName} stands at the forefront of AI replication, offering unparalleled accuracy in creating your digital twin. This precision ensures that every nuance of your personality and communication style is captured, making your digital counterpart as realistic as possible.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-4.png')} /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <Wabinar />
            </div >

            <Footer />
        </>
    )
}

export default Dashboard;