import React, { useState } from 'react'
import { MdModeEditOutline } from 'react-icons/md'
import { onGenerateAi } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { themeColor } from '../../../Global/Global'
import { onGenerateCharacter } from '../../../Redux/Actions/CloneSettingAction'

const FinalCloneBox = ({ handleUpdate, img, setImg, data, setData, onImageChange, loader }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        aiImage: false
    })
    const [text, setText] = useState("")
    const [loaderGen, setLoaderGen] = useState({
        generate: false,
        submit: false
    })
    const generateImage = () => {
        // let obj = {
        //     text,
        //     cId: data.id
        // }
        // setLoaderGen({
        //     ...loaderGen,
        //     generate: true
        // })
        // dispatch(onGenerateAi(obj, setImage, loaderGen, setLoaderGen))

        setLoaderGen({ ...loaderGen, submit: true })
        let obj = {
            cId: data.id,
            text: text,
            input: img
        };
        dispatch(onGenerateCharacter(obj, state, setState, loaderGen, setLoaderGen))

    }

    const handleAdd = () => {
        setImg(state.aiImage.outputUrl)
        setState({
            ...state,
            aiImage: false
        })
    }

    return (
        <div>
            <h3 className='text-center'>This is how your clone will look.</h3>

            <div className='clone-img block' style={{ position: "relative" }}>
                <div className='clone-img-holder'>
                    <img src={img} alt="" />
                    {loader.upload ?
                        <div className='edit_loader'>
                            <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: 20 }} />
                        </div>
                        :
                        <div className='clone-img-up'>
                            <MdModeEditOutline style={{ color: "#fff", cursor: "pointer" }} />
                            <input
                                type="file"
                                onChange={(e) => onImageChange(e)}
                            />
                        </div>
                    }

                    {/* <div className='clone-img-up'>
                        <MdModeEditOutline style={{ color: "#fff", cursor: "pointer" }} />
                        <input
                            type="file"
                            onChange={(e) => onImageChange(e)}
                        />
                    </div> */}
                </div>

            </div>




            <hr className='mt-5' />

            <div className='input-wrap'>
                <label className='label' htmlFor="">Generate Image from Prompt</label>
                <div className='input-outer'>
                    <textarea
                        className='common-inp alt'
                        rows="5"
                        placeholder='Image Description'
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>
                <div style={{ textAlign: "right" }}>
                    <button className='theme-btn ' onClick={generateImage}><span>{loaderGen.submit ? <> Generating <i className='fa fa-spin fa-spinner' /></> : "Generate AI Image"}</span></button>
                </div>
            </div>
            {state.aiImage ?
                <div className='clone-img block'>
                    <div style={{ height: 200, width: 200, margin: "auto", display: "flex", alignItems: "center", border: `1px solid ${themeColor}`, borderRadius: 10, padding: 2 }}>
                        <img src={state.aiImage.outputUrl} alt="" />
                    </div>
                    <button className='theme-btn mt-2' onClick={handleAdd}><span>Add</span></button>
                </div>
                : ""}

            <div className='input-wrap d-flex justify-content-between'>
                <button className='theme-btn full' onClick={() => handleUpdate("final")}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"}</span></button>
            </div>

        </div>
    )
}

export default FinalCloneBox