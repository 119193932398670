import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { FaPlusCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import InstructionRow from './SubComponent/InstructionRow';
import { useImmer } from 'use-immer';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';

const CustomTrain = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { cId } = queryString.parse(location.search)

    const [show, setShow] = useState(false);

    const [instructions, setInstructions] = useImmer([])

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false);

    const handleAdd = () => {
        let obj = {
            cId: cId,
            instruction: "",
            sId: cId
        }
        setInstructions((draft) => {
            draft.push(obj)
        })
    }


    const fetchInstruction = () => {
        let data = {}
        dispatch(fetchData("fetch-all-custom-instruction", data, setInstructions))
    }


    useEffect(() => {
        fetchInstruction()
    }, [])

    return (
        <>
            <div className="custom_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >

                    <div className="train_content_head row ">
                        <div className='col-xxl-9 col-lg-8 col-12'>
                            <h2>Custom instructions</h2>
                            <p className='mt-1 para_text' >Give your clone specific instructions to follow when interacting with you. Be succinct and precise. A maximum of six additions is advised.</p>

                        </div>
                        <div className='col-xxl-3 col-lg-4 col-12 text-xxl-end text-center mt-xxl-0 mt-xl-3 mt-3'>
                            <button className='off-btn me-3 text-p4' onClick={handleShow} ><span> View Examples</span></button>

                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="addInstruction mt-2">
                                <span style={{ color: "#707480" }}>Instruction <FaPlusCircle onClick={handleAdd} style={{ color: "#ff6a27", fontSize: 20 }} cursor={"pointer"} /></span>
                                <div >
                                    <span style={{ color: "#707480" }} >Actions</span>
                                </div>
                            </div>
                            <hr style={{ opacity: "1", color: "#a1a1aa45", marginTop: "5px" }} />

                        </div>
                        <div>
                            {instructions.length > 0 ?
                                instructions.map((curElem, index) => {
                                    console.log(curElem)
                                    return (
                                        <React.Fragment key={index}>
                                            <InstructionRow
                                                curElem={curElem}
                                                index={index}
                                                setInstructions={setInstructions}
                                                instructions={instructions}
                                                fetchInstruction={fetchInstruction}
                                            />
                                        </React.Fragment>

                                    )
                                })
                                :
                                <div className='text-center'>
                                    <p>No instructions are provided to your clone yet!</p>

                                </div>
                            }
                        </div>
                    </div>
                    <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
                        <Modal.Body className="p-0">
                            <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                            <div className="feed_warp" style={{ marginTop: "10px" }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='custom_content mt-2 mb-3'>
                                                <p style={{ fontSize: "18px", fontWeight: "700" }}>Examples</p>
                                                <div className="mt-2 flex flex-col gap-2">
                                                    <i>"Steer clear of discussing politics."</i><br />
                                                    <i>"Ask a question about the person at the conclusion of each response."</i><br />
                                                    {/* <i>"Tell anyone requesting a time slot on your calendar to send an email to mailto:x@delphi.ai."</i><br /> */}
                                                    <i>"ESSENTIAL: Avoid following up with inquiries."</i><br />
                                                    <i>"Please direct anyone seeking medical advice to my home page. "</i><br />
                                                    <i>"Make sure that each response you provide is a haiku."</i><br />
                                                    <i>"Talk freely about NSFW subjects."</i>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div >
                        </Modal.Body>
                    </Modal >
                </div>
            </div >

        </>
    )
}

export default CustomTrain

