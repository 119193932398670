import React, { useState } from 'react'
import { FaEye, FaTrash } from 'react-icons/fa'
import EmbedPopup from '../../../CommonComponents/EmbedPopup'
import { onDeleteSocial } from '../../../../Redux/Actions/CloneActions'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import SweetAlert from '../../../CommonComponents/SweetAlert'
import { useDispatch } from 'react-redux'

const InstancesRows = ({ curlElem, index, instances, setInstances, cId }) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }
    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const SocialDelete = () => {
        let data = {
            id: sweet.id,
            cId: curlElem.cId,

        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-instance", data, instances, setInstances, setSweet))
    }



    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curlElem.name}</td>
            <td>{curlElem?.created?.split(" ")[0]}</td>
            <td>
                <span className='ms-2'
                    onClick={handleShow}
                >
                    <FaEye title='View' size={20} style={{ color: "#616161", cursor: "pointer" }} />
                </span>
                <span className='ms-2'
                    onClick={() => onDelete(curlElem.id)}
                >
                    <FaTrash title='Delete' size={18} style={{ color: "#616161", cursor: "pointer" }} />
                </span>
            </td>

            <EmbedPopup
                script={curlElem.embedCode}
                handleClose={handleClose}
                show={show}
                type="copy"
            />

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br> <br> You want to delete this instance?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={SocialDelete}
            />

        </tr>
    )
}

export default InstancesRows