import React, { useState } from 'react'
import { Nav, Button, Tab } from 'react-bootstrap';
import Purpose from './PurposeTrain';
import Voice from './VoiceTrain';
import Tone from './Tone/ToneTrain';
import CustomTrain from './CustomTrain';
import SuggestedQuestions from './SuggestedQuestions/SuggestedQuestions';
import CloneImage from '../CommonSection/CloneImage';
import Feeds from './Feeds/Feeds';
import Content from './Content/Content';


const CloneTrain = ({ clone }) => {
    const [activeFetch, setActiveFetch] = useState(false)
    return (

        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Train</h2>
                    <CloneImage
                        image={clone.data.image}
                        radiness={+clone.data.radiness}
                    />
                </div>
                <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div className="col-12  ">
                        <Nav variant="pills" className="welcome-studio train_nav">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Content</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Purpose</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Voice</Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link eventKey="forth">Tone</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth">Custom Instructions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth">Suggested Questions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="seventh">Feeds</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-12">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Content
                                    activeFetch={activeFetch}
                                    setActiveFetch={setActiveFetch}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Purpose />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <Voice />
                            </Tab.Pane>
                            <Tab.Pane eventKey="forth">
                                <Tone
                                    activeFetch={activeFetch}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <CustomTrain />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                                <SuggestedQuestions />
                            </Tab.Pane>
                            <Tab.Pane eventKey="seventh">
                                <Feeds />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>

                </Tab.Container>
            </div>



        </>
    )



}

export default CloneTrain
