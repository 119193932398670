import React from 'react'

const settingMenu = () => {
    return (
        <>
            <div className="site-wrap" style={{ paddingBottom: "0", minHeight: "100%" }}>
                <div className="site-container" style={{ maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}>
                    <div className="site-container for-editor" style={{ maxWidth: "100%", padding: "0" }}></div>
                    <div className="nav flex-column  account-nav text-lg-end text- text-start " id="v-pills-tab" role="tablist" aria-orientation="vertical">

                        <a className="nav-link active" id="v-pills-wlOutreach-tab" data-toggle="pill"
                            href="#v-pills-wlOutreach" role="tab" aria-controls="v-pills-wlOutreach"
                            aria-selected="false" style={{ color: "#fff", fontSize: "18px" }}>Outreach
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default settingMenu;