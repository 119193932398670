import produce from "immer"
const initialState = {
    data: {
        conversations: [],
        message: []
    }
}

export const ChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_CONVERSATION":
            return produce(state, (draft) => {
                draft.data.conversations = action.payload
            })

        case "ON_SET_MESSAGE":
            return produce(state, (draft) => {
                draft.data.message.push(action.payload)
            })

        case "ADD_MESSAGES":
            return produce(state, (draft) => {
                draft.data.message = action.payload
            })
        default:
            return state
    }
}