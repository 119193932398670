import React, { useState } from 'react'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { IoPencil } from 'react-icons/io5'
import { onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'

const FeedRow = ({ curElem, feeds, setFeeds }) => {

    const dispatch = useDispatch();
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
            cId: curElem.cId,
            sId: curElem.sId
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-feed", data, feeds, setFeeds, setSweet))
    }

    return (
        <>
            <div className="purpose_main_content mb-4 mt-4" >
                <div className='d-flex align-items-center'>
                    <a href={curElem.url} target='_blank' className='ms-2' style={{ color: "#000", fontWeight: "500" }}>{curElem.url}</a>
                </div>
                <div className='d-flex'>
                    <i className="fa-solid fa-trash-can ms-2" style={{ color: "#a1a1aa", fontSize: "15px", cursor: "pointer" }} onClick={onDelete}></i>
                </div>
            </div>
            <hr style={{ opacity: "1", color: "#a1a1aa45" }} />

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b><br><br> You want to delete this feed?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </>
    )
}

export default FeedRow
