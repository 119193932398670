import React, { useEffect, useState } from 'react'
import { BiSearch } from 'react-icons/bi';
import { FaArrowCircleDown, FaPlus } from 'react-icons/fa'
import { FaUserCircle } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { onFetchAllQuestion, onFetchSaveTopics, onSuggested } from '../../../../../Redux/Actions/CloneActions';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { useSelector } from 'react-redux';
import AddQuestionModal from './AddQuestionModal';
import QuestionTable from './QuestionTable';
import Topics from './Topics';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';

const SuggestedQuestions = () => {

    const location = useLocation()
    const dispatch = useDispatch();
    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false);
    const handleExport = () => {
        if (questions.length > 0) {
            let data = {
                cId: cId
            }
            setLoader({
                ...loader,
                submit: true
            });
            dispatch(onSuggested(data, loader, setLoader))
        }
        else {
            dispatch(setAlert
                ("Please add suggested questions to export to it!", "danger"))
        }
    }



    const [state, setState] = useState({
        topicArray: [],
        selectedTopic: -1
    });
    const [search, setSearch] = useState("");
    const [questions, setQuestions] = useState([]);
    const [loader, setLoader] = useState({
        fetch: false,
    });

    const fetchQuestions = () => {
        let data = {
            cId: cId
        }
        setLoader({ ...loader, fetch: true })
        dispatch(onFetchAllQuestion(data, setQuestions, loader, setLoader))
    }

    const fetchTopic = () => {
        let data = {
            sId: studioData.id,
            cId: cId,
        }
        dispatch(onFetchSaveTopics(data, state, setState))
    }

    useEffect(() => {
        if (cId && studioData.id) {
            fetchTopic();
        }
        fetchQuestions();
    }, [cId, studioData.id])

    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-xxl-8 col-xl-5 col-12">
                            <div className="train_content_head justify-content-between ">
                                <div className="d-flex align-items-center justify-content-xl-start  justify-content-center flex-wrap">
                                    <h2>Suggested questions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4  col-xl-6 col-12 mt-xxl-0 mt-xl-3 mt-3">
                            <div className="d-flex justify-content-xl-end  justify-content-center  ms-2 ">
                                <button className='off-btn  f-13 me-3' onClick={handleExport}><span><FaArrowCircleDown className='me-1' />Export</span></button>
                                <button className='theme-btn f-13' onClick={handleShow}><span>Add question <FaPlus /></span></button>
                            </div>
                        </div>
                        <div className="train_content_search mt-4">
                            <div className="col-xxl-2 col-xl-3 col-12">
                                <div style={{ position: "relative" }}>
                                    <input
                                        type="text" className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Search"
                                        name='search'
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <span className="inpx-icon"><BiSearch style={{ color: "#a1a1aa" }} /></span>
                                </div>
                            </div>

                        </div>
                        <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "25px" }} />
                        <div className="row">
                            <div className="col-12">
                                <div className="input-wrap mt-2">
                                    <Topics
                                        state={state}
                                        setState={setState}
                                        cId={cId}
                                        sId={studioData.id}
                                    />
                                </div>
                                <QuestionTable
                                    state={state}
                                    loader={loader}
                                    search={search}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <AddQuestionModal
                show={show}
                handleClose={handleClose}
                topicArray={state.topicArray}
                fetchQuestions={fetchQuestions}
            />
        </>
    )
}

export default SuggestedQuestions
