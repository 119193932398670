import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import comma from '../../../../../../assets/images/SocialIcons/comma.png'
import { TfiReload } from "react-icons/tfi";
import { useDispatch } from 'react-redux';
import { onContinueData, onReloadQuestion } from '../../../../../../Redux/Actions/CloneActions';

const AutoSuggested = (props) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        continue: false,
        reload: false
    })
    const [question, setQuestion] = useState("")
    const [answere, setAnswere] = useState("")



    const handleReload = (isLoader) => {
        let data = {
            cId: props.cId
        }
        if (isLoader) {
            setLoader({
                ...loader,
                reload: true
            })
        }
        dispatch(onReloadQuestion(data, setQuestion, loader, setLoader))
    }




    const handleContinue = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            continue: true
        })
        let val = {
            cId: props.cId,
            sId: props.sId,
            sourceId: 7,
            source: "Auto",
            sourceType: "",
            data: {
                question: question,
                answere: answere
            }
        }
        dispatch(onContinueData("save-auto-question", val, props.fetchContent, props.handleClose, loader, setLoader))
    }

    useEffect(() => {
        handleReload()
    }, [])

    return (
        <>
            <div className="content_modal" >
                <div className='d-flex justify-content-between align-items-center mt-3' >

                    <h2 className=''>Auto Suggested Questions</h2>

                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>
                <form className="container mt-5 " onSubmit={handleContinue}>
                    <div className="row h-100">
                        <div className="col-12 h-100">
                            <div className="d-flex align-items-center w-100 " style={{ height: "285px" }}>
                                <div className="auto_main w-100 h-100">
                                    <div className="autoWrapper shadow-auto">
                                        <div className="comma_img pt-3 ps-3 pe-3"> <img src={comma} alt="" style={{ width: "17px", height: "100%" }} /></div>

                                        <div className="auto_text pt-1 ps-2 pe-2">
                                            <p className='text-center'>{question}</p>
                                        </div>

                                    </div>
                                    <div className="autoWrapper-2 shadow-auto">
                                    </div>
                                    <div className="autoWrapper-3 shadow-auto">
                                    </div>
                                </div>
                                <div className="reaload" onClick={() => handleReload("active")} style={{ cursor: "pointer" }}>
                                    {loader.reload ? <i className='fa fa-spin fa-spinner' style={{ color: "#ff6a27", fontSize: "14px" }} /> :
                                        <TfiReload style={{ color: "#ff6a27", fontSize: "14px" }} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-wrap">
                                <label htmlFor="" >
                                    Answer
                                </label>
                                <div div className="input-outer">
                                    <textarea
                                        className="common-inp alt"
                                        value={answere}
                                        onChange={(e) => setAnswere(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                                <button className='theme-btn f-13' type='submit'><span>{loader.continue ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save Answer"} </span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AutoSuggested
