import React, { useState } from 'react'
import { IoPencil } from 'react-icons/io5'
import AddQuestionModal from './AddQuestionModal';
import SweetAlert from '../../../../CommonComponents/SweetAlert';
import { onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';

const QuestionsRow = ({ curElem, index, questions, setQuestions, topicArray }) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
            cId: curElem.cId,
            sId: curElem.sId,
            tId: curElem.tId
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-suggested-question", data, questions, setQuestions, setSweet))
    }

    return (
        <>
            <div className="purpose_main_content mb-4 mt-4" >
                <div className='d-flex align-items-center'>
                    <span className='para_text f-13 me-1'>{index + 1}</span>
                    <p className='ms-2'>{curElem.questions}</p>
                </div>
                <div className='d-flex'>
                    <IoPencil style={{ color: "#a1a1aa", fontSize: "15px", cursor: "pointer" }} onClick={handleShow} />
                    <i className="fa-solid fa-trash-can ms-2" style={{ color: "#a1a1aa", fontSize: "15px", cursor: "pointer" }} onClick={onDelete}></i>
                </div>
            </div>
            <hr style={{ opacity: "1", color: "#a1a1aa45" }} />

            <AddQuestionModal
                show={show}
                handleClose={handleClose}
                curElem={curElem}
                type="editQuestion"
                questions={questions}
                setQuestions={setQuestions}
                topicArray={topicArray}
            />

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br><br> You want to delete this question?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </>
    )
}

export default QuestionsRow
