import React, { useEffect, useState } from 'react'
import QuestionsRow from './QuestionsRow'

const QuestionTable = (props) => {

    const { state, loader, search, questions, setQuestions } = props
    const [currentQuestions, setCurrentQuestions] = useState([]);

    useEffect(() => {
        let data = [...questions]
        if (search) {
            data = data.filter(val => val.questions.includes(search))
        }
        if (state.selectedTopic !== -1) {
            data = data.filter(val => +val.tId === +state.selectedTopic)
        }
        setCurrentQuestions(data)
    }, [state.selectedTopic, questions, search])

    return (
        <div>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="addInstruction mt-2">
                        <span style={{ color: "#707480" }}>Suggested Questions </span>
                        <div >
                            <span style={{ color: "#707480" }} >Actions</span>
                        </div>
                    </div>
                    <hr style={{ opacity: "1", color: "#a1a1aa45", marginTop: "5px" }} />

                </div>
                <div>
                    {currentQuestions.length > 0 ?
                        currentQuestions.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <QuestionsRow
                                        index={index}
                                        curElem={curElem}
                                        questions={questions}
                                        setQuestions={setQuestions}
                                        topicArray={state.topicArray}
                                    />
                                </React.Fragment>
                            )
                        })
                        :
                        <div className='text-center'>
                            {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                                <p>{search && questions.length > 0 ? "No data found." : "You do not have questions yet"}</p>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuestionTable
