import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import face from '../../../../assets/images/do.png';
import face2 from '../../../../assets/images/donts.png';
import thumb from '../../../../assets/images/thumb.svg';
import unthumb from '../../../../assets/images/Unthumb.svg';

const InstructionModal = ({ show, handleClose }) => {
    return (
        <Modal className=' talking_heading_modal' show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                <div className="Face_narration">
                    <h2>Instructions </h2>
                    <div className="col-lg-12 col-12" style={{ margin: "0 auto" }}>
                        <div className="Face_narration_section">
                            <ul className='Face_narration_do'>
                                <div className='Thumb'>  <img src={thumb} alt="Thumb" /> <span>The Do's</span></div>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Use a single face in the frame  </span></li>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Opt for a close-up shot of your full face.</span></li>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Position your face in the top half of the frame.</span></li>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Ensure the image is in HD quality.</span></li>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Choose a solid, clean background.</span></li>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Make sure the lighting is bright and even.</span></li>
                                <li> <div><AiOutlineCheck className="green" /></div><span>Make sure the face is of a human being.</span></li>
                                <div className="Face_narration_img">
                                    <img src={face} alt="" />
                                </div>
                            </ul>

                            <ul className='Face_narration_dont'>
                                <div className='Thumbs'>   <img src={unthumb} alt="" />  <span>The Don'ts</span></div>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Avoid using multiple faces in one image.</span></li>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Do not use distant or partial face shots.</span></li>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Keep your face away from the bottom of the frame.</span></li>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Don't use blurry or low-quality pictures.</span></li>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Refrain from using busy or patterned backgrounds.</span></li>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Stay away from dimly lit or shadowy lighting.</span></li>
                                <li> <div><AiOutlineCheck className="red" /></div><span>Do not use face of any pet or any other animal.</span></li>

                                <div className="Face_narration_img">
                                    <img src={face2} alt="" />
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='common-input-wrap text-end'>
                    <button className='theme-btn mt-2 ' type='button' onClick={handleClose}><span>Cancel</span></button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default InstructionModal
