import React from 'react'
import { Nav, Button, Tab } from 'react-bootstrap';
import UserAudience from './UserAudience';
import ConversationsAudience from './ConversationsAudience';
const Audience = () => {
    return (
        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Audience</h2>

                </div>
                <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div className="col-xxl-6 col-xl-8 col-12 ">
                        <Nav variant="pills" className="welcome-studio" style={{ justifyContent: "start" }}>
                            <Nav.Item>
                                <Nav.Link eventKey="first">Users</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Conversations</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </div>
                    <div className="col-12">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <UserAudience />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <ConversationsAudience />
                            </Tab.Pane>



                        </Tab.Content>
                    </div>

                </Tab.Container>
            </div>

        </>
    )
}

export default Audience
