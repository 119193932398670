import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { onChangeMonetization, onSaveCloneProfile, onUpdateSetting } from '../../../../Redux/Actions/CloneSettingAction'
import ProductRows from './ProductRows'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { fetchData } from '../../../../Redux/Actions/CommonActions'
import CreateProductModal from './CreateProductModal'
const Affiliate = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const { cId } = queryString.parse(location.search)
    const settingData = useSelector(state => state.cloneSetting.data)

    const [productData, setProductData] = useState([])
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState({
        save: false,
        fetch: false
    });

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onUpdateSetting(name, value))
    }

    const fetchProducts = () => {
        let data = {
            cId: cId
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-product", data, setProductData, loader, setLoader))
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (

        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>

                <div className="row">
                    <div className="settings_head mt-3">
                        <h2>Affiliate</h2>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className="Usage_wrap mt-5" style={{ background: "#fff" }}>
                            <div>
                                <div className='d-flex justify-content-between align-items-center  mt-1 '>
                                    <div className='d-flex flex-column mt-1 '>
                                        <h6 className='' style={{ color: "#a1a1aa", fontWeight: "600" }}>Total products</h6>
                                        <p style={{ color: "#000", fontWeight: "500", fontSize: "27px" }}>{productData.length}</p>
                                    </div>
                                </div>
                                <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "25px", borderStyle: "dashed" }} />
                            </div>
                        </div>

                    </div>

                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className='Paywall mt-5'>
                            <h2 style={{ color: "#18181b", fontSize: "16px", fontWeight: "600" }}>Messages before product</h2>
                            <p className='f-13 mt-2 mb-2' style={{ color: "#71717a" }}>How much messaging can be sent before a product appears.</p>

                            <div className='inp_radio_wrap' style={{ maxWidth: "576px" }}>
                                {/* <span style={{ color: "#ff6a27", fontWeight: "600" }}>{settingData.productCapture}</span> */}

                                <input
                                    type="number"
                                    className="common-inp mt-2"
                                    min="0"
                                    max="10"
                                    name='productCapture'
                                    id="customRange2"
                                    value={settingData.productCapture}
                                    onChange={handleChange}
                                />
                                <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>
                                    The number of messages before login is required is <span style={{ color: "#ff6a27" }}>10</span>. This is where you may update it.
                                </p>
                            </div>
                        </div>


                    </div>

                    <div className='col-lg-4 col-md-6 col-12'>
                        <div className='d-flex align-items-center justify-content-center' style={{ height: "100%" }}>
                            <button onClick={handleShow} type='button' className="theme-btn me-0"><span> + Create Product</span>  </button>

                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex justify-content-end mt-5">
                            <button onClick={handleSubmit} className="theme-btn me-0"><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span>  </button>
                        </div>
                    </div>


                </div>

                <div className="train_flex" >

                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h3>Products</h3>
                        {/* <p className=' para_text text-center' style={{ fontWeight: "normal" }} >Look who's talking to your Clone.</p> */}
                    </div>
                    {/* <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='off-btn me-3'><span>Import </span></button>
                        <button className='theme-btn'><span>Add Product</span></button>
                    </div> */}
                </div>
                <hr className='mt-3 mb-4' style={{ opacity: "1", color: "#a1a1aa45" }} />

                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>S.No.</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>

                                {productData.length > 0 ?
                                    productData.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ProductRows
                                                    index={index}
                                                    cId={cId}
                                                    curElem={curElem}
                                                    productData={productData}
                                                    setProductData={setProductData}
                                                    fetchProducts={fetchProducts}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                                                <p>You do not have product yet.</p>
                                            }
                                        </td>

                                    </tr>

                                }

                            </table>

                        </div>
                    </div>

                </div>
            </div >

            <CreateProductModal
                show={show}
                handleClose={handleClose}
                cId={cId}
                fetchProducts={fetchProducts}
            />
        </>
    )

}

export default Affiliate
