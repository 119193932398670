import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { onCreateFeed } from '../../../../../Redux/Actions/CloneActions';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';

const CreateFeedModal = ({ show, handleClose, fetchFeeds }) => {

    const location = useLocation()
    const dispatch = useDispatch();
    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)

    const [state, setState] = useState({
        type: "youtube",
        url: ""
    });
    const [loader, setLoader] = useState({
        save: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let okUrl = false
        if (state.type === "youtube") {
            let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            let matches = state.url.match(p);
            if (matches) {
                okUrl = true
            } else {
                dispatch(setAlert("Please provide a valid youtube url", "danger"))
            }
        }
        if (state.type === "twitter") {
            let p = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
            let matches = state.url.match(p);
            if (matches) {
                okUrl = true
            } else {
                dispatch(setAlert("Please provide a valid twitter url", "danger"))
            }
        }
        if (state.type === "blog") {
            // let p = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
            // let matches = state.url.match(p);
            // if (matches) {
            //     okUrl = true
            // } else {
            //     dispatch(setAlert("Please provide a valid blog url", "danger"))
            // }

            okUrl = true

        }

        if (okUrl) {
            setLoader({ ...loader, save: true })
            let data = {
                cId: cId,
                sId: studioData.id,
                type: state.type,
                url: state.url
            }
            dispatch(onCreateFeed(data, loader, setLoader, handleClose, fetchFeeds))
        }
    }
    useEffect(() => {
        if (show) {
            setState({
                type: "youtube",
                url: ""
            })
        }
    }, [show])

    return (
        <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
            <Modal.Body className="p-0">
                <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                <div className="feed_warp p-3" style={{ marginTop: "10px" }}>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feed_mod_head">
                                        <h2 className='mb-3'>Add a Feed</h2>
                                        <p className='mt-1 mb-1 para_text'>Feed type</p>
                                    </div>
                                    <div className="input-outer w-100">
                                        <select
                                            className="common-inp alt"
                                            placeholder='Type'
                                            name="type"
                                            value={state.type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="youtube">Youtube</option>
                                            {/* <option value="podcast">Podcast</option> */}
                                            <option value="twitter">Twitter</option>
                                            <option value="blog">Blog</option>
                                            <option value="RSS">RSS</option>
                                        </select>

                                    </div>
                                    <div>
                                        <p className='mt-1 mb-1 para_text'><span className='text-capitalize'>{state.type}</span> URL</p>
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter URL here"
                                            name="url"
                                            value={state.url}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="feed_btn text-end">
                                        <button type='submit' disabled={loader.save} className='theme-btn me-3 text-p4 mt-3'><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Modal.Body>
        </Modal >
    )
}

export default CreateFeedModal
