import React from 'react'

const Popular = () => {
    return (
        <>
            <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12">
                            <div className="Train_content_head ">
                                <h2>Popular Topics </h2>
                                <p className='mt-2 para_text text-center' style={{ fontWeight: "normal", fontSize: "18px" }} >No Data to display</p>
                            </div>
                            <div className="Train_content_head " style={{ marginTop: "80px" }}>
                                <h2>Popular Questions </h2>
                                <p className='mt-2 para_text text-center' style={{ fontWeight: "normal", fontSize: "18px" }} >No Data to display</p>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Popular
