import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap"
import { onUpdateContent } from '../../../../../Redux/Actions/CloneActions'
import { useDispatch } from 'react-redux'

const UpdateContent = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({ update: false })
    const [data, setData] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleUpdate = () => {
        let obj = {
            cId: props.curElem.cId,
            sId: props.curElem.sId,
            id: props.curElem.id,
            data: data
        }
        setLoader({
            ...loader,
            update: true
        })
        dispatch(onUpdateContent(obj, props.fetchData, props.handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (props.curElem.data) {
            setData(props.curElem.data)
        }
    }, [props.curElem])


    return (
        <Modal className="theme-modal ai_img_wrap content-modal content_md ds" show={props.show} centered>
            <Modal.Body >
                <div className='row' style={{ height: "100%" }}>
                    <div className='col-12 pt-4 pe-4 ps-4'>
                        <div className='p-2'>
                            <h5>Update Content</h5>
                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                            {props.curElem.source === "Manual" || props.curElem.source === "Auto" ?

                                <div className="container p-0">
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="input-wrap">
                                                <label htmlFor="">
                                                    {props.curElem.sourceType === "T&I" ? "Title" : "Question"}
                                                </label>
                                                <div className="input-outer">
                                                    <input
                                                        className="common-inp alt"
                                                        type="text"
                                                        placeholder="Question"
                                                        value={props.curElem.sourceType === "T&I" ? data.title : data.question}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-wrap">
                                                <label htmlFor="">
                                                    {props.curElem.sourceType === "T&I" ? "Information" : "Answer"}
                                                </label>
                                                <div div className="input-outer">
                                                    <textarea
                                                        className="common-inp alt"
                                                        name={props.curElem.sourceType === "T&I" ? 'information' : 'answere'}
                                                        value={props.curElem.sourceType === "T&I" ? data.information : data.answere}
                                                        onChange={handleChange}
                                                        rows={7}
                                                        required

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <textarea
                                    value={data.text}
                                    name="text"
                                    onChange={handleChange}
                                    className='inp-small py-2'
                                    style={{
                                        minHeight: '475px',
                                        overflowX: 'hidden',
                                        overflowY: "auto",
                                        padding: '20px',
                                        resize: "none",
                                    }}
                                />
                            }
                        </div>
                    </div>
                    <div className="col-12 p-3">
                        <div className=' text-end me-3'>
                            <button className='theme-btn bdr me-1 text-p4' onClick={props.handleClose}><span>Cancel</span></button>
                            <button className='theme-btn  text-p4' onClick={handleUpdate}><span> {loader.update ? <>Updating <i className='fa fa-spin fa-spinner' /> </> : "Update"}</span></button>
                        </div>
                    </div>
                </div>

            </Modal.Body>

        </Modal>
    )
}

export default UpdateContent