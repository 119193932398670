import React, { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onChangePurpose, onUpdatePurpose } from '../../../../Redux/Actions/CloneActions';
import PurposeRequirement from './SubComponent/PurposeRequirement';
import { Link, useLocation } from 'react-router-dom';
import queryString from "query-string"
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import { useImmer } from 'use-immer';

const PurposeTrain = () => {
    const dispatch = useDispatch()

    const location = useLocation()
    const { cId } = queryString.parse(location.search)

    const clone = useSelector(state => state.clone)
    const [requirments, setRequiremnts] = useImmer([])

    const [newActive, setNewActive] = useState(false)

    const handleUpdatePurpose = () => {
        let data = {
            cId: clone.studioData.cId,
            purpose: clone.studioData.purpose
        }
        dispatch(onUpdatePurpose(data))
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangePurpose(name, value))
    }

    const handleAdd = () => {
        let obj = {
            cId: clone.studioData.cId,
            requirement: "",
            sId: ""
        }

        setRequiremnts((draft) => {
            draft.push(obj)
        })
        setNewActive(true)
    }

    const fetchRequirment = () => {
        let data = {
            cId
        }
        dispatch(fetchData("fetch-purpose-requirement", data, setRequiremnts))
    }

    useEffect(() => {
        if (cId) {
            fetchRequirment()
        }
    }, [cId])

    return (
        <>
            <div className="purpose_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="Train_content_head ">
                                    <h2>Purpose </h2>
                                    <p className='mt-1 para_text ' >This field influences every facet of your clone, influencing its behaviors and training.</p>
                                </div>
                            </div>
                            <div className="purpose_content_para">
                                <textarea
                                    name='purpose'
                                    value={clone.studioData.purpose}
                                    onChange={handleChange}
                                    onBlur={handleUpdatePurpose}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                    <div className="row" style={{ padding: "10px 20px" }}>
                        <div className="col-12">
                            <div className="purpose_content_head ">
                                <h3>Clone Requirements </h3>
                            </div>
                            <div className="purpose_main_para">
                                <p className='para_text'>
                                    The "Purpose" above is analyzed to identify requirements automatically. Their effect on your Clone Readiness Score is direct. A score between 0% and 50% suggests insufficient training, a score between 50% and 99% shows moderate training and a score of 100% indicates sufficient training.
                                </p>

                            </div>

                        </div>
                    </div>
                    <hr style={{ opacity: "1", color: "#a1a1aa45" }} />
                    <div className="col-12">
                        {requirments.length > 0 ?
                            requirments.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <PurposeRequirement
                                            curElem={curElem}
                                            index={index}
                                            fetchRequirment={fetchRequirment}
                                            setRequiremnts={setRequiremnts}
                                            newActive={newActive}
                                            lastLength={requirments.length - 1}
                                            setNewActive={setNewActive}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : ""}
                        {newActive ? null :
                            <>
                                <div className="purpose_main_content mb-4 mt-4" style={{ padding: "0 65px" }}>
                                    <span className='para_text' style={{ cursor: "pointer" }} onClick={handleAdd}> Add Requirement <FiPlus className='ms-1' fontSize={26} /></span>
                                </div>
                                <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                            </>
                        }

                    </div>

                </div>


            </div >

        </>
    )
}

export default PurposeTrain
