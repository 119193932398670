import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { onCreateProduct, onUpdateProduct } from '../../../../Redux/Actions/CloneSettingAction'
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions'
import Swal from 'sweetalert2'
import { themeColor } from '../../../../Global/Global'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { setAlert } from '../../../../Redux/Actions/AlertActions'


const CreateProductModal = (props) => {

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const dispatch = useDispatch();
    const location = useLocation();
    const { cId } = queryString.parse(location.search)

    const [percent, setPercent] = useState(0);
    const [state, setState] = useState(false);
    const [loader, setLoader] = useState({
        submit: false,
        upload: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const fetchImgMedia = (url, name) => {
        let obj = {
            target: {
                name: name,
                value: url
            }
        }
        handleChange(obj)
    }

    const onImageChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchImgMedia, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const websiteRegex = /^(https?:\/\/)(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/i;
        if (!websiteRegex.test(state.affiliateLink)) {
            dispatch(setAlert("Please enter valid url!", "danger"))
            return;
        }
        if (!state.image) {
            dispatch(setAlert("Please add product image!", "danger"))
            return;
        }

        setLoader({ ...loader, submit: true })
        let data = { ...state, cId: cId }
        if (props.type === "update") {
            dispatch(onUpdateProduct(data, loader, setLoader, props.fetchProducts, props.handleClose, setState))
        } else {
            dispatch(onCreateProduct(data, loader, setLoader, props.fetchProducts, props.handleClose, setState))
        }

    }

    useEffect(() => {
        if (props.curElem) {
            setState({
                id: props.curElem.id,
                name: props.curElem.name,
                description: props.curElem.description,
                image: props.curElem.image,
                btnText: props.curElem.btnText,
                affiliateLink: props.curElem.affiliateLink
            })
        }
    }, [props.curElem])

    return (
        <Modal className='theme-modal video-modal feed' show={props.show} onHide={props.handleClose} centered>
            <Modal.Body className="p-0">
                <div className='modalClose text-end' onClick={props.handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                <div className="feed_warp mb-3" style={{ marginTop: "10px" }}>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feed_mod_head p-2">
                                        <h2 className='mb-2'>Create Product</h2>

                                        <div className="input-outer w-100 mb-2">
                                            <label>Name</label>
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Enter Name Here"
                                                name='name'
                                                value={state.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-outer w-100 mb-2">
                                            <label>Description</label>
                                            <textarea
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Enter Description Here"
                                                name='description'
                                                value={state.description}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-outer w-100 mb-2 ">

                                            <label>Image</label>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <input
                                                    className="common-inp alt"
                                                    type="file"
                                                    onChange={(e) => onImageChange(e)}
                                                    accept='image/*'
                                                    style={{ width: "80%" }}
                                                />
                                                <div className='productLoader'>
                                                    {loader.upload ? <span> <i className='fa fa-spin fa-spinner' style={{ color: themeColor }} /></span>
                                                        :
                                                        state.image ? <img src={state.image} style={{ height: "50px", width: "50px", objectFit: "cover", marginLeft: '5px' }} alt="" /> : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-outer w-100 mb-2">
                                            <label>Button Text</label>
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Enter Button Text Here"
                                                name='btnText'
                                                value={state.btnText}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-outer w-100 mb-2">
                                            <label>Affiliate Link</label>
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Enter Link Here"
                                                name='affiliateLink'
                                                value={state.affiliateLink}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="feed_btn text-end">
                                        <button type='submit' disabled={loader.submit} className='theme-btn me-3 text-p4 mt-3'>
                                            {props.type === "update" ?
                                                <span>{loader.submit ? <>Updating <i className='fa fa-spin fa-spinner' /></> : "Update"}</span>
                                                :
                                                <span>{loader.submit ? <>Creating <i className='fa fa-spin fa-spinner' /></> : "Create"}</span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Modal.Body>
        </Modal >
    )
}

export default CreateProductModal