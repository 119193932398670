import React, { useState } from "react";
import SmtpClient from './SmtpClient'
import SettingModal from './SettingModal'
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onFetch } from "../../../../Redux/Actions/outreachAction";
import SendMail from "./SendMail";

const SettingContent = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [outreachData, setOutreachData] = useState([])
    const [buttonAdd, setButtonAdd] = useState("Add")
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        name: "",
        email: "",
        username: "",
        password: "",
        url: "",
        port: "",
        ssl: "ssl"
    })
    const handleShow = (tableId) => {
        if (tableId) {
            let data = outreachData.find(({ id }) => id === tableId)
            setButtonAdd("Update")
            setState({
                ...state,
                name: data.name,
                email: data.from_email,
                username: data.username,
                password: data.password,
                url: data.url,
                port: data.port,
                ssl: data.ssl_tls,
                id: data.id
            })
        }
        else {
            setButtonAdd("Add")
            setState({
                ...state,
                name: "",
                email: "",
                username: "",
                password: "",
                url: "",
                port: "",
                ssl: "ssl"
            })
        }
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }

    const fetchOutreach = () => {
        setLoader(true)
        dispatch(onFetch(setOutreachData, setLoader))
    }

    useEffect(() => {
        fetchOutreach()
    }, [])

    return (
        <>
            <div className="col-lg-8 tabRight " s>
                <div className="tabCont-right">
                    <div className="tab-content" id="v-pills-tabContent">

                        <div className="tab-pane fade show active"  >
                            <div className="tabepaneIn">
                                <div className="tabTitle outreach">
                                    <h3 style={{ fontWeight: "700" }}>SMTP</h3>
                                    <div onClick={() => handleShow(false)} >
                                        <button className="theme-btn"><span>Add SMTP</span></button>
                                    </div>

                                </div>

                                <div className="tabepaneIn-main mt-3">
                                    <div className="tabepaneIn-cont g">
                                        <div className="profileBlock-wrap profileBlock-wrap-last">
                                            <div className="profileBlock tableWrap mt-0">
                                                <table className="table table-striped table-dark">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.NO</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Username</th>
                                                            <th scope="col">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {outreachData.length > 0 ?
                                                            outreachData.map((row, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <SmtpClient
                                                                            row={row}
                                                                            index={index}
                                                                            handleShow={handleShow}
                                                                            fetchOutreach={fetchOutreach}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            }) : ""}

                                                    </tbody>
                                                </table>
                                                {outreachData.length === 0 ?
                                                    <div className="text-center my-2">
                                                        {loader ?
                                                            <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#00D0B0", fontSize: "20px" }} /> :
                                                            <p>SMTP accounts are not created yet</p>}
                                                    </div> : ""
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <SendMail
                                data={outreachData}
                            />
                        </div>


                    </div>
                </div>

            </div>
            <SettingModal
                show={show}
                state={state}
                setState={setState}
                handleClose={handleClose}
                fetchOutreach={fetchOutreach}
                buttonAdd={buttonAdd}
            />
        </>
    )
}

export default SettingContent;