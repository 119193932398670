import React, { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import { onAddCustomePurpose } from '../../../Redux/Actions/CloneActions'
import CustomePurposeRows from './CustomePurposeRows';

const Purpose = ({ data, setData, handleUpdate, loader }) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [purpose, setPurpose] = useState([])
    const [customePurpose, setCustomePurpose] = useState([])
    const [isActive, setIsActive] = useState(false)
    const [addLoader, setAddLoader] = useState(false)

    const handleAdd = (e, purpose) => {
        if (e.target.checked) {
            setData((draft) => {
                draft.purpose.push(purpose)
            })
        } else {
            setData((draft) => {
                draft.purpose = draft.purpose.filter((curElem) => {
                    return curElem !== purpose
                })
            })
        }
    }
    const addCustomePurpose = (e) => {
        e.preventDefault()
        let obj = {
            purpose: text,
            cId: data.id
        }
        setAddLoader(true)
        dispatch(onAddCustomePurpose(obj, fetchCustomePurpose, setText, setAddLoader))
    }


    const fetchCustomePurpose = () => {
        dispatch(fetchData("fetch-custom-purpose", {}, setCustomePurpose))
    }

    const fetchTopics = () => {
        dispatch(fetchData("fetch-purpose", {}, setPurpose))
    }

    useEffect(() => {
        fetchTopics()
        fetchCustomePurpose()
    }, [])

    return (
        <div>
            {/* <h3>What is your clone's purpose?</h3> */}
            <h3>What’s the goal of your clone?</h3>
            <p>Its conversational style and the specific information it requires will be shaped by this.
            </p>
            <div className='clone-purpose'>
                <ul>
                    {purpose.length > 0 ?
                        purpose.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <label className='check-select'>
                                        <input type="checkbox" checked={data.purpose.includes(curElem.purpose)} onChange={(e) => handleAdd(e, curElem.purpose)} />
                                        <span className='check-select-mark'></span>
                                    </label>
                                    <p>{curElem.purpose}</p>

                                </li>
                            )
                        })
                        : ""}
                    {customePurpose.length > 0 ?
                        customePurpose.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomePurposeRows
                                        curElem={curElem}
                                        data={data}
                                        isActive={isActive}
                                        setIsActive={setIsActive}
                                        handleAdd={handleAdd}
                                        fetchCustomePurpose={fetchCustomePurpose}
                                    />
                                </React.Fragment>

                            )
                        })
                        : ""}
                </ul>
            </div>

            <div className='custom-purpose'>
                <div className='input-wrap'>
                    <form className='input-outer' onSubmit={addCustomePurpose}>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Add Custom Goal'
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            onClick={(e) => setIsActive(false)}
                            required
                        />
                        <button style={{ border: "none" }} type='submit' className='add-purpose-btn'>{addLoader ? <i className='fa fa-spinner fa-spin' /> : <BsPlus style={{ fontSize: "28px" }} />}</button>
                    </form>
                </div>
            </div>

            <div className='input-wrap'>
                <button className='theme-btn full' onClick={() => handleUpdate(2)}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
            </div>
        </div>
    )
}

export default Purpose