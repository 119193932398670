import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { onExportUserCsv, onFetchUser } from '../../../../Redux/Actions/CloneActions';
import { BiSearch } from 'react-icons/bi';
import dummyUser from "../../../../assets/images/dummyUser.png"
import { FaArrowCircleDown } from 'react-icons/fa';
import { setAlert } from '../../../../Redux/Actions/AlertActions';
const UserAudience = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [userName, SetUserName] = useState("")
    const [userData, setUserData] = useState([])
    const { cId } = queryString.parse(location.search)
    const [loader, setLoader] = useState({
        fetch: false,
        submit: false
    })

    const handleExport = () => {
        if (userData.length > 0) {
            let data = {
                cId: cId,
                type: "chatBotUser"

            }
            setLoader({
                ...loader,
                submit: true
            });
            dispatch(onExportUserCsv(data, loader, setLoader))
        }
        else {
            dispatch(setAlert("Please add content first to export it!", "danger"))
        }
    }

    const fetchUser = () => {
        setLoader({
            ...loader,
            fetch: true
        });
        let data = {
            cId: cId
        }
        dispatch(onFetchUser(data, setUserData, loader, setLoader))
    }
    const handleSearch = (e) => {
        SetUserName(e.target.value)
    }

    useEffect(() => {
        fetchUser()
    }, [])


    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2>Users </h2>
                        <p>See who's speaking to your clone</p>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='off-btn me-3'><span onClick={handleExport}><FaArrowCircleDown className='me-1' style={{ color: "#71717a" }} />{loader.submit ? <> Exporting <i className='fa fa-spinner fa-spin' /> </> : "Export"}</span></button>
                    </div>
                </div>
                <div className="train_content_search mt-5">
                    <div className="row">
                        <div className="col-xxl-2 col-xl-3 col-12">
                            <div style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Search users"
                                    value={userName}
                                    onChange={handleSearch}
                                />
                                <span className="inpx-icon"><BiSearch style={{ color: "#a1a1aa", fontSize: "20px" }} /></span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>S.No</th>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Description</th>
                                    <th>Created</th>
                                </tr>
                                {userData.length > 0 ?
                                    userData.filter((user) => {
                                        return user.name.includes(userName) && user.name !== ""
                                    }).map((curlElem, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td><img src={curlElem.image ? curlElem.image : dummyUser} style={{ height: "50px" }} /></td>
                                                <td>{curlElem.name}</td>
                                                <td>{curlElem.email}</td>
                                                <td>{curlElem.text}</td>
                                                <td>{curlElem.created.slice(0, 10)}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={6} className='text-center py-5' >
                                            {loader.fetch ? <><i className='fa fa-spin fa-spinner fetch-loader' /></> : "No users on this clone yet!"}
                                        </td>
                                    </tr>

                                }


                            </table>

                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}

export default UserAudience
