import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onChangeMonetization, onSaveCloneProfile } from '../../../../Redux/Actions/CloneSettingAction';


const Memberships = () => {
    const dispatch = useDispatch()
    const settingData = useSelector(state => state.cloneSetting.data)

    const [loader, setLoader] = useState({
        save: false,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "enable") {
            dispatch(onChangeMonetization(name, checked))
        } else {
            dispatch(onChangeMonetization(name, value))
        }
    }


    return (
        <>
            <div className="studio_setting ps-3">
                <div className="row">
                    {/* <div className="settings_head mt-3">
                        <h2>Monetization</h2>                        
                    </div> */}
                    <div className="col-12 mt-3">
                        <div className="train_content_head row">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h2>Monetization</h2>
                                <div className="opt-select">
                                    <p className='mx-2'>
                                        If you do not want monetization, you should disable it.
                                    </p>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='enable'
                                            checked={settingData.monetization?.enable}
                                            onChange={handleChange}
                                        />
                                        <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        settingData.monetization?.enable ?
                            <>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className="Usage_wrap mt-5" style={{ background: "#fff" }}>
                                        <div>
                                            <div className='d-flex justify-content-between align-items-center  mt-1 '>
                                                <div className='d-flex flex-column mt-1 '>
                                                    <p className='' style={{ color: "#a1a1aa", fontWeight: "600" }}>Total Revenue</p>
                                                    <p style={{ color: "#000", fontWeight: "500", fontSize: "27px" }}>${settingData?.monetization?.revenue}</p>
                                                </div>
                                            </div>
                                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "25px", borderStyle: "dashed" }} />
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className='Paywall mt-5'>
                                        <h2 style={{ color: "#18181b", fontSize: "16px", fontWeight: "600" }}>Messages before paywall</h2>
                                        <p className='f-13 mt-2 mb-2' style={{ color: "#71717a" }}>How much messaging can be sent before a paywall appears.</p>

                                        <div className='inp_radio_wrap' style={{ maxWidth: "576px" }}>
                                            {/* <span style={{ color: "#ff6a27", fontWeight: "600" }}>{settingData?.monetization?.paywall}</span> */}

                                            <input
                                                type="number"
                                                className="common-inp mt-2"
                                                min="0"
                                                max="10"
                                                name='paywall'
                                                id="customRange2"
                                                value={settingData?.monetization?.paywall}
                                                onChange={handleChange}
                                            />
                                            <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>
                                                The number of messages before login is required is <span style={{ color: "#ff6a27" }}>10</span>. This is where you may update it.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className='payment_account '>
                                        <h2 style={{ color: "#18181b", fontSize: "16px", fontWeight: "600" }}>Price ($)</h2>

                                        <input
                                            type='number'
                                            className='common-inp'
                                            name='price'
                                            value={settingData?.monetization?.price}
                                            onChange={handleChange}
                                        />

                                    </div>

                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <div className='input-wrap'>
                                        <label className='label' htmlFor="">Pay Message</label>
                                        <div className="input-outer">
                                            <textarea
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Enter pay message"
                                                name='payMessage'
                                                value={settingData.monetization.payMessage}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-lg-4 col-md-6 col-12'>
                                        <div className='input-wrap'>
                                            <label className='label' htmlFor="">Button Text</label>
                                            <div className='input-outer'>
                                                <input
                                                    className='common-inp alt'
                                                    type="text"
                                                    placeholder='Enter button text here'
                                                    name='buttonText'
                                                    value={settingData.monetization.buttonText}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : ""
                    }
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="d-flex justify-content-end mt-5">
                            <button onClick={handleSubmit} className="theme-btn me-0"><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span>  </button>
                        </div>
                    </div>

                </div>
            </div >

        </>
    )
}
export default Memberships
