import React, { useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import AccountNav from "../Profile/AccountNav";

import { BiTrash, BiChevronDownSquare, BiSearch } from "react-icons/bi"
import { MdModeEditOutline } from "react-icons/md"
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import { addAccData, fetchAccData } from "../../../Redux/Actions/AccountManageAction";
import MemberRow from "./MemberRow";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";
import { themeColor } from "../../../Global/Global";


const AccountManagement = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false,
        fetch: false
    })
    const [teamMember, setTeamMember] = useState([])
    const [clients, setClients] = useState([])
    const [user, setUser] = useState({
        type: 'team',
        email: '',
        name: '',
        password: ''
    })
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (user.password) {
            if (pattern.test(user.password)) {
                setLoader({
                    ...loader,
                    create: true
                });
                dispatch(addAccData(user, fetchMembers, loader, setLoader, setUser))
                setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    const fetchMembers = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchAccData(setTeamMember, setClients, loader, setLoader))
        // dispatch(fetchData("fetch-team-account", data, setTeamMember, loader, setLoader))
    }

    useEffect(() => {
        fetchMembers()
    }, [])

    return (
        <>
            <Header />
            <TitleBar title="Account Management" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="account-wrap">
                        <div className="account-nav">
                            <AccountNav />
                        </div>

                        <div className="account-cont">
                            <h2 className="account-title">Create Account</h2>

                            <form className="account-block" onSubmit={handleSubmit}>
                                <h5>Create Account</h5>
                                <div className="input-wrap">
                                    <label htmlFor="">Account Type</label>
                                    <div className="input-outer">
                                        <select
                                            className="common-inp alt"
                                            name="type"
                                            onChange={handleChange}
                                            value={user.type}
                                        >
                                            <option value="team">Team Member</option>
                                            <option value="client">Client</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Account Email</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="email"
                                            placeholder="Enter Email"
                                            value={user.email}
                                            onChange={handleChange}
                                            name='email'
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Account Name</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={user.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Password</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="password"
                                            placeholder="Enter Password"
                                            name='password'
                                            value={user.password}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                </div>
                                <div className="input-wrap text-end">
                                    <button className="theme-btn" type="submit"><span>{loader.create ? <>Creating  <FaSpinner className="spin" /> </> : "Create"}</span></button>
                                </div>
                            </form>

                            <h2 className="account-title mt-5">Manage Client Account</h2>
                            <div className="account-block">
                                <div className="table-responsive">
                                    <table className="table theme-table">
                                        <tr>
                                            <th>Name</th>
                                            <th>Email Address</th>
                                            <th>Created</th>
                                            <th className="text-end">Actions</th>
                                        </tr>

                                        {clients.length > 0 ?
                                            clients.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <MemberRow
                                                            curElem={curElem}
                                                            fetchMembers={fetchMembers}
                                                            type={"Client"}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <tr className='text-center'>
                                                <td colSpan={4}>
                                                    {
                                                        loader.fetch ?
                                                            <FaSpinner className="spin" style={{ fontSize: 20, color: themeColor }} />
                                                            : "You have not created any client yet!"
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>

                            <h2 className="account-title mt-5">Manage Team Members Account</h2>
                            <div className="account-block">
                                <div className="table-responsive">
                                    <table className="table theme-table">
                                        <tr>
                                            <th>Name</th>
                                            <th>Email Address</th>
                                            <th>Created</th>
                                            <th className="text-end">Actions</th>
                                        </tr>
                                        {teamMember.length > 0 ?
                                            teamMember.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <MemberRow
                                                            curElem={curElem}
                                                            fetchMembers={fetchMembers}
                                                            type={"Team"}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <tr className='text-center'>
                                                <td colSpan={4}>
                                                    {
                                                        loader.fetch ?
                                                            <FaSpinner className="spin" style={{ fontSize: 20, color: themeColor }} />
                                                            : "You have not created any team members yet!"
                                                    }
                                                </td>
                                            </tr>
                                        }

                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default AccountManagement;