import React, { useEffect, useRef, useState } from 'react'
import { BiTrash, BiEdit } from "react-icons/bi";
import SweetAlertTwo from './SweetAlertTwo';
import { useDispatch } from 'react-redux';
import { onChatEdit, onDeleteConversation } from '../../../Redux/Actions/ChatAction';

import { useSelector } from 'react-redux';
import { themeColor } from '../../../Global/Global';

const Conversations = ({ curElem, fetchConv, setSelectedCid, selectedCid, firstId, handlePerform }) => {

    const convRef = useRef()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [isReadOnly, setIsReadOnly] = useState(true)
    const [convName, setConvName] = useState(curElem.name)
    const [info, setInfo] = useState({
        enable: false,
        id: -1
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }
    const handleDelete = (e) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: curElem.id,
            enable: true
        })
    }

    const performDelete = (Swal) => {
        let data = {
            cId: info.id
        }
        dispatch(onDeleteConversation(data, fetchConv, setSelectedCid, handlePerform, Swal))
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }

    // edit name

    const handleUpdate = () => {
        if (!isReadOnly) {
            if (convName !== "") {
                let data = {
                    cId: curElem.id,
                    name: convName
                }
                dispatch(onChatEdit(data, setIsReadOnly, fetchConv))
            }
        }
    }

    const handleEdit = (e) => {
        e.stopPropagation()
        if (+auth.user.isClient !== 1) {
            convRef.current.focus()
            setIsReadOnly(false)
        }
    }

    const selectConversation = (convId) => {
        if (+curElem.id === +selectedCid) { }
        else {
            setSelectedCid(convId)
        }
    }
    useEffect(() => {
        if (selectedCid === 0) {
            setSelectedCid(firstId)
        }
    }, [curElem.id])

    useEffect(() => {
        setConvName(curElem.name || `conversation_${curElem.id}`)
    }, [curElem.name, curElem.id])

    return (
        <div
            className={`${+curElem.id === +selectedCid ? "chat_active" : ""} chat-list-single cursor-pointer`}
            onClick={() => selectConversation(curElem.id)}
            onDoubleClick={handleEdit}
        >
            <input
                ref={convRef}
                type='text'
                value={convName}
                onChange={(e) => setConvName(e.target.value)}
                className='conver-input-field'
                readOnly={isReadOnly}
                onBlur={handleUpdate}
                title={convName}
                style={{ cursor: isReadOnly ? "pointer" : "" }}
                maxLength={100}
            />
            {+auth.user.isClient === 1 ? "" :
                <div className="chat-list-action">
                    <button onClick={(e) => handleEdit(e)}><BiEdit /></button>
                    <button onClick={(e) => handleDelete(e)}><BiTrash /></button>
                </div>}

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor={themeColor}
                cancelButtonColor={themeColor}
                confirmButtonText='Yes, delete!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={performDelete}
                onCancel={handleCancel}
            />
        </div>
    )
}

export default Conversations