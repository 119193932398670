import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import { BiTrash, BiChevronDownSquare, BiSearch } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux";
import { fetchArticles, fetchVideos } from "../../../Redux/Actions/HelpActions";
import { useEffect } from "react";
import TitleBar from "../../CommonComponents/TitleBar";

import SupportVideo from "./SupportVideo";
import { FaSpinner } from "react-icons/fa";
import { themeColor } from "../../../Global/Global";


const Help = () => {

    const dispatch = useDispatch()
    const rebrand = useSelector(state => state.rebrand.data)
    const help = useSelector(state => state.help)

    const [q, setQ] = useState('')
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }



    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <Header />
            <TitleBar title="Help" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="help-container">
                        <div className="project-search">
                            <h2 className="text-center">What do you want to know ?</h2>
                            <div className="project-search-in">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="project-search-single">
                                            <div className="inpx-wrap icon-left">
                                                <input
                                                    className="inpx"
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder="Search"
                                                    onChange={(e) => setQ(e.target.value)}
                                                />
                                                <span className="inpx-icon"><BiSearch /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="help-list">
                            <div className="row">
                                {
                                    help.article.length > 0 ?
                                        help.article.filter((art) => {
                                            return art.tags.toLowerCase().includes(q.toLowerCase())
                                        }).map((curElem, index) => {
                                            return (
                                                <div className='col-sm-6 col-lg-3 text-light' key={index}>
                                                    <NavLink to={`/help-post?id=${curElem.id}`} style={{ color: "#fff" }}>
                                                        <div className='help-single'>
                                                            <div className='help-icon'><img src={curElem.logo} alt='' /></div>
                                                            <h2>{curElem.name}</h2>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='col-sm-12 mt-3 text-center'>
                                            {
                                                loader.article ?
                                                    <FaSpinner className="spin" style={{ fontSize: 25, color: themeColor }} />
                                                    : ""
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <SupportVideo
                        videos={help.videos}
                        loader={loader}
                    />

                </div>
            </div>

            <div className="webnair">
                <div className="webnair-in">
                    <div className="webnair-main">
                        <div className="help-txt">
                            <h2>Can't Find Your Answer? We're Here for You!</h2>
                            <p>It happens to the best of us. Sometimes, you might have a question that isn't covered in our FAQs or documentation. Don't worry - we've got your back!</p>
                            <a className="theme-btn mt-4" href="https://vineasx.helpscoutdocs.com/" target="_blank" ><span>Contact</span></a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Help;