import React, { useEffect, useState } from 'react'
import { Modal, Nav, Tab } from "react-bootstrap"
import { AiOutlineClose } from 'react-icons/ai';
import PopularModal from '../TrainContentModal/PopularModal';
import { WebsitesModal } from '../TrainContentModal/WebsitesModal';
import { YouTubeModal } from '../TrainContentModal/YouTubeModal';
import SocialsModal from '../TrainContentModal/SocialsModal';
import FilesModal from '../TrainContentModal/FilesModal';
import TakingModal from '../TrainContentModal/TakingModal';
import MessagingModal from '../TrainContentModal/MessagingModal';
import ManualModal from '../TrainContentModal/ManualModal/ManualModal';
import AutoSuggested from '../TrainContentModal/AutoSuggested/AutoSuggested';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { useSelector } from 'react-redux';

const AddContentModal = (props) => {
    const location = useLocation()
    const { cId } = queryString.parse(location.search)
    const cloneStudio = useSelector(state => state.clone.studioData)

    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        if (props.show) {
            setActiveTab(0)
        }
    }, [props.show])

    return (
        <Modal className="theme-modal ai_img_wrap content-modal ds" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body style={{ height: "100%" }}>
                <div style={{ height: "100%" }}>

                    <Tab.Container id="tabs-example">
                        <div className="row" style={{ height: "100%" }}>
                            <div className="col-3" style={{ height: "100%" }}>

                                <div className="modal_top">
                                    <p style={{ fontWeight: "600" }}>Add Content</p>
                                </div>

                                <Nav variant="pills" className="side_Train_tab">
                                    <Nav.Item onClick={() => setActiveTab(0)}>
                                        <Nav.Link className={activeTab === 0 ? "active" : ""}>Popular</Nav.Link>
                                    </Nav.Item>
                                    {props.contentNav.length > 0 ?
                                        props.contentNav.map((curElem, index) => {
                                            return (
                                                <Nav.Item key={index} onClick={() => setActiveTab(+curElem.id)}>
                                                    <Nav.Link className={activeTab === +curElem.id ? "active" : ""}>{curElem.name}</Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                        : ""}
                                </Nav>
                            </div>
                            <div className="col-9">
                                <Tab.Content>
                                    <Tab.Pane className={activeTab === 0 ? 'active show' : ""}>
                                        <PopularModal
                                            handleClose={props.handleClose}
                                            setActiveTab={setActiveTab}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 1 ? 'active show' : ""}>
                                        <WebsitesModal
                                            handleClose={props.handleClose}
                                            cId={cId}
                                            fetchContent={props.fetchContent}
                                            sId={cloneStudio.id}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 2 ? 'active show' : ""}>
                                        <YouTubeModal
                                            handleClose={props.handleClose}
                                            cId={cId}
                                            fetchContent={props.fetchContent}
                                            sId={cloneStudio.id}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 3 ? 'active show' : ""}>
                                        <SocialsModal handleClose={props.handleClose} />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 4 ? 'active show' : ""}>
                                        <FilesModal
                                            handleClose={props.handleClose}
                                            cId={cId}
                                            fetchContent={props.fetchContent}
                                            sId={cloneStudio.id}
                                            type={"Files"}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 5 ? 'active show' : ""}>
                                        <FilesModal
                                            handleClose={props.handleClose}
                                            cId={cId}
                                            fetchContent={props.fetchContent}
                                            sId={cloneStudio.id}
                                            type={"Media Files"}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 6 ? 'active show' : ""}>
                                        <ManualModal
                                            handleClose={props.handleClose}
                                            cId={cId}
                                            fetchContent={props.fetchContent}
                                            sId={cloneStudio.id}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane className={activeTab === 7 ? 'active show' : ""}>
                                        <AutoSuggested
                                            handleClose={props.handleClose}
                                            cId={cId}
                                            fetchContent={props.fetchContent}
                                            sId={cloneStudio.id}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                                {/* <div className="col-12 text-end mt-4">
                                    <button className='theme-btn me-3 text-p4' ><span>Continue</span></button>

                                </div> */}
                            </div>
                        </div>

                    </Tab.Container>
                </div>


            </Modal.Body>
        </Modal>
    )
}

export default AddContentModal