import React, { useState } from 'react'
import { FaChevronRight, FaHand } from 'react-icons/fa6'
import arrow from "../../../../../../assets/images/arrow.png"
import { AiOutlineClose } from 'react-icons/ai';
import PasteQuestion from './PasteQuesiton';
import { GoQuestion } from "react-icons/go";

const ManualModal = (props) => {
    const [addQue, setAddQue] = useState("manual")

    const handleAddQue = (type) => {
        setAddQue(type)
    }

  

    return (
        <>
            <div className="content_modal">
                <div className='d-flex justify-content-between align-items-center mt-3' >
                    {addQue === "manual" ? <h2 className=''>Manual</h2>
                        :
                        <p className='f-15 ' style={{ color: "#71717a" }}> <span onClick={() => handleAddQue("manual")} style={{ cursor: "pointer", fontWeight: "500" }}>Manual</span> <span><FaChevronRight style={{ margin: "0 3px", color: "#71717a", fontSize: "11px" }} /></span><span style={{ color: "#18181b" }}>{addQue === 'pasteQue' ? "Paste Questions and Answers" : "Paste Information"}</span></p>
                    }
                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>
                <div className="container ">
                    <div className="row flex-direction-lg-row flex-direction-coloum">
                        {addQue === "manual" ?
                            <>
                                <div className="col-lg-6 col-12">
                                    <div className='pop_card' onClick={() => handleAddQue("pasteQue")} style={{ cursor: "pointer" }}>
                                        <div className="d-flex justify-content-end">
                                            <img src={arrow} alt="" style={{ height: "20px" }} />
                                        </div>
                                        <div >
                                            <span> <FaHand color='#ff6a27' fontSize={22} /></span>
                                            <h3>Paste Questions and Answers</h3>
                                        </div>
                                        <h4>Input information in the form of questions & answers</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className='pop_card' onClick={() => handleAddQue("pasteInfo")} style={{ cursor: "pointer" }}>
                                        <div className="d-flex justify-content-end">
                                            <img src={arrow} alt="" style={{ height: "20px" }} />
                                        </div>
                                        <div >
                                            <span>
                                                <span> <GoQuestion nd color='#ff6a27' fontSize={22} /></span>
                                            </span>
                                            <h3>Paste Information</h3>
                                        </div>
                                        <h4>Input information in the form of plain text</h4>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <PasteQuestion
                                    addQue={addQue}
                                    props={props}
                                />
                            </>
                        }
                    </div>
                </div>

            </div>
          
        </>
    )
}

export default ManualModal
