import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Tab from 'react-bootstrap/Tab';
import TrainingNav from "./TrainingNav";
import queryString from "query-string"
import { useSelector } from "react-redux";
import TitleBar from "../../CommonComponents/TitleBar";

const HelpPost = () => {
    let location = useLocation();
    const articleId = queryString.parse(location.search).id;
    const article = useSelector(state => state.help.article);

    return (
        <>
            <Header />
            <TitleBar title="Training" />
            <div className="site-wrap">
                <div className="site-container">
                    <div className="account-wrap large">
                        <Tab.Container id="left-tabs-example">
                            <div className="account-nav">
                                <TrainingNav
                                    article={article}
                                    articleId={articleId}
                                />
                            </div>
                            <div className="account-cont">
                                {/* <h5>Support/Account Management</h5> */}
                                <Tab.Content>
                                    {article.length > 0 ?
                                        article.map((curElem, index) => {
                                            return (
                                                <Tab.Pane className='fade show active'>
                                                    <React.Fragment key={index}>
                                                        {
                                                            +curElem.id === +articleId ?
                                                                curElem.items.map((item, ind) => {
                                                                    return (
                                                                        <div className="account-block" key={ind}>
                                                                            <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                                <div className="training-block">
                                                                                    <h3 className="text-light">
                                                                                        {item.title}
                                                                                    </h3>
                                                                                    <div className="mt-4">
                                                                                        <img src={item.feature_url} className="img-fluid" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    )
                                                                })
                                                                : ''}
                                                    </React.Fragment>
                                                </Tab.Pane>
                                            )
                                        })
                                        : ""}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default HelpPost;