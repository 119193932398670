import React, { useState } from 'react'
import { appName } from '../../../../Global/Global';
import { useSelector } from 'react-redux';
import { onSaveCloneProfile, onUpdateCustomWarning, onUpdateCustomWarningToggle, onUpdateSetting } from '../../../../Redux/Actions/CloneSettingAction';
import { useDispatch } from 'react-redux';

const Privacy = () => {
    const dispatch = useDispatch()
    const settingData = useSelector(state => state.cloneSetting.data)

    const [lang, setLang] = useState([])
    const [loader, setLoader] = useState({
        save: false,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...settingData }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onUpdateCustomWarning(name, value))
    }

    const handleChangeToggle = (e) => {
        const { name, checked } = e.target
        dispatch(onUpdateCustomWarningToggle(name, checked))
    }

    return (
        <div>
            <div className="settings_wrap ">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Privacy</h2>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="col-lg-7 col-12 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="settings_content">
                                <h3 className='mb-1' style={{ fontWeight: "500" }}>Custom Warning</h3>
                                <p className='mb-2 para_text2 f-13'>Consistently shown at the chat's top</p>
                            </div>
                            <div className="setting_input message">
                                <div className="opt-select">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='customWarning'
                                            checked={settingData.customWarning.enable}
                                            onChange={handleChangeToggle}
                                        />
                                        <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="textAreaWrap mt-2">
                            {settingData.customWarning.enable ?
                                <div className="input-outer">
                                    <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                        <textarea
                                            className="common-inp alt"
                                            type="email"
                                            rows="4"
                                            name='customWarning'
                                            value={settingData.customWarning.warningText}
                                            onChange={handleChange}
                                            placeholder="Enter custom warning"
                                            required
                                        />

                                    </div>
                                </div>
                                :
                                ""
                            }
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button type='submit' className="theme-btn me-0"><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span>  </button>
                        </div>
                    </form>

                    {/* <div className="col-lg-7 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className=" settings_content col-10">
                                <h3 style={{ fontWeight: "500" }}>Show Citations</h3>
                                <p className="para_text2 f-13">Enables users to examine sources</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="settings_content col-10">
                                <h3 style={{ fontWeight: "500" }}>Disable Delphi Talk Page</h3>
                                <p className="para_text2 f-13">Everyone's access to your AiDuals talk page, aidusla.com, will be blocked. Use Prodigy to upgrade and disable</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button className="theme-btn me-0"><span>Save</span> </button>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Privacy;
