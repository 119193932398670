import React from "react";
import logo from "../../assets/images/logo.png"

function LoginHeader() {

    return(
        <>
          <header className="site-header">
             <div className="header-in">
                <div className="site-header-main">
                    <div className="logo"><img alt="" src={logo} /></div>
                </div>
             </div>
          </header>

        </>
    )
}

export default LoginHeader;