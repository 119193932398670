import React from 'react';


const Collaborators = () => {
    return (
        <div>
            <div className="studio_setting ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Collaborators</h2>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="studio_setting mt-5">
                            <h3 className='mb-1'>Invite  Collaborators</h3>
                            <div className="setting_input mt-3">
                                <div className="input-outer">
                                    <div className="row align-items-center">
                                        <div className="col-lg-10 col-md-12 col-12">
                                            <input
                                                className="common-inp alt"
                                                type="email"
                                                placeholder="user@example.com"
                                                name='email'
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-12 mt-lg-0 mt-2">
                                            <button className='theme-btn me-0'> <span>Invite</span> </button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="studio_setting mt-5">
                            <h3 className='py-2'>Creator</h3>
                            <div className="creator_wrap d-flex justify-content-between align-items-center">
                                <div className="info d-flex align-items-center">
                                    <div className="img_wrap email_icon">
                                        <span style={{fontSize:'20px',color:"#fff",fontWeight:"500"}}>E</span>
                                    </div>
                                    <div className=" ps-3 detail">
                                        <p className="name f-13" style={{ fontWeight: "600", color: "#000" }}>Erick <span className='para_text2'>(you)</span></p>
                                        <p className='email f-13 para_text2' style={{ fontWeight: "500" }}>erickmcee64@gmail.com</p>
                                    </div>
                                </div>
                                <div className="tag">
                                    <span className='para_text2 f-13' style={{ fontWeight: "600" }}>Creator</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="studio_setting mt-5">
                            <div className='py-2 d-flex justify-content-between '>
                                <h3>Collaborators</h3>
                                <h3><span className='para_text2'>0</span>/2</h3>
                            </div>
                            <p className='para_text2 f-13'>This clone does not have any Collaborators.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collaborators
