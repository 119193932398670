import React, { useState } from 'react'
import { FaGreaterThan, FaYoutube } from "react-icons/fa";
import arrow from "../../../../../assets/images/arrow.png"
import website from "../../../../../assets/images/website.png"
import { HiMicrophone } from "react-icons/hi2";
import uploads from "../../../../../assets/images/uploads.png"
import { AiOutlineClose } from 'react-icons/ai';

const PopularModal = (props) => {


    return (
        <>
            <div className="content_modal">
                <div className='d-flex justify-content-between align-items-center mt-3' >
                    <h2 className=''>Popular</h2>
                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>

                <div className="container">
                    <div className="row flex-direction-lg-row flex-direction-coloum">
                        <div className="col-lg-6 col-12">
                            <div className='pop_card' style={{ cursor: "pointer" }} onClick={() => props.setActiveTab(2)}>
                                <div className="d-flex justify-content-end">

                                    <img src={arrow} alt="" style={{ height: "20px" }} />
                                </div>
                                <div >
                                    <span> <FaYoutube color='red' fontSize={22} /></span>
                                    <h3>Upload from YouTube</h3>
                                </div>
                                <h4>Single video or an entire playlist / channel.</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className='pop_card' style={{ cursor: "pointer" }} onClick={() => props.setActiveTab(1)} >
                                <div className="d-flex justify-content-end">
                                    <img src={arrow} alt="" style={{ height: "20px" }} />
                                </div>
                                <div >
                                    <span>
                                        <img src={website} alt="" />
                                    </span>
                                    <h3>Upload from Website</h3>
                                </div>
                                <h4>Directly upload content from your website</h4>
                            </div>
                        </div>

                        {/* <div className="col-12">
                            <div className='upload_wrap mt-3'>
                                <div className='upload_content_wrap'>
                                    <img src={uploads} alt="" style={{ height: "98px" }} />
                                    <input type="file" />
                                    <h3 >Upload Files or Images</h3>
                                    <p className="text-p3 text-center"><span className="text-brand">Click to browse</span> or drag and drop</p>
                                </div>


                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default PopularModal
