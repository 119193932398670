import React, { useEffect, useState } from 'react'
import ContentEditable from "react-contenteditable"
import { useDispatch } from 'react-redux'
import { onDeleteInstruction, onSubmitInstruction, onUpdateInstruction } from '../../../../../Redux/Actions/CloneActions'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { setAlert } from '../../../../../Redux/Actions/AlertActions'

const InstructionRow = ({ curElem, index, setInstructions, fetchInstruction }) => {
    const dispatch = useDispatch()

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const [insData, setInstData] = useState(curElem)

    const handleChange = (e) => {
        setInstData({
            ...insData,
            instruction: e.target.value
        })
    }

    const handleSubmit = (e) => {
        let data = { ...insData }
        if (data.id) {
            dispatch(onUpdateInstruction(data))
        } else {
            if (data.instruction) {
                dispatch(onSubmitInstruction(data, setInstData))
            }
        }
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: insData.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteInstruction(data, fetchInstruction, setSweet))
    }


    useEffect(() => {
        if (curElem) {
            setInstData(curElem)
        }
    }, [curElem])

    return (
        <div className=''>
            <div className="purpose_main_content mb-4 " >
                <div className='d-flex ' style={{ width: "100%" }}>
                    <span className='para_text f-13 me-1'>{index + 1}</span>
                    <textarea
                        value={insData.instruction}
                        className='me-2'
                        onChange={handleChange}
                        style={{ width: "100%", paddingLeft: 10, border: "none", resize: "none" }}
                        onBlur={handleSubmit}
                        rows={2}
                    />
                </div>
                {insData.id ?
                    <div className='d-flex'>
                        <i className="fa-solid fa-trash-can ms-2" style={{ color: "#a1a1aa", fontSize: "15px", cursor: "pointer" }} onClick={onDelete}></i>
                    </div> : null}

            </div>
            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br><br> You want to delete this instruction?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </div>
    )
}

export default InstructionRow