import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { onDeleteOutreach } from "../../../../Redux/Actions/outreachAction";

const SmtpClient = ({ row, index, handleShow, fetchOutreach }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        button: "Detete",
        show: false,
        id: -1
    })

    const handleDelete = (idToDete) => {
        setState({
            ...state,
            id: idToDete,
            show: true
        })
    }

    const onConfirm = () => {
        setState({
            ...state,
            button: "Deleting..."
        })
        dispatch(onDeleteOutreach(state, setState, fetchOutreach))
    }


    return (
        <>
            <tr>
                <td scope="row"><h6
                    className="text-wrapper  m-0">{index + 1}</h6></td>
                <td><h6 className="text-wrapper m-0">{row.name}</h6></td>
                <td><h6 className="text-wrapper m-0">{row.username}</h6></td>
                <td className="expand-button">
                    <span
                        className="action-icon delete-icon remove mb-1 mt-md-1 cursor-pointer mx-1"
                        title="Edit"
                        aria-expanded="true"
                        onClick={() => handleShow(row.id)}

                    >
                        <i className="fas fa-edit"></i>
                    </span>

                    <span
                        className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1 cursor-pointer"
                        style={{ cursor: "pointer", color: "rgb(240, 83, 89)" }}
                        title="Delete"
                        onClick={() => handleDelete(row.id)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </span>
                </td>
            </tr>
            <SweetAlert
                warning
                showCancel
                confirmBtnText={state.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setState({
                    ...state,
                    show: false,
                    id: -1
                })}
                focusCancelBtn
                show={state.show}
            >
                <div style={{ color: "gray", fontWeight: "bold" }}>
                    You want to delete this SMTP Information?
                </div>
            </SweetAlert>
        </>
    )

}
export default SmtpClient;