import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner, FaTrash } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { onSubmitAddTone } from '../../../../../Redux/Actions/CloneActions'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions'
import SweetAlert from '../../../../CommonComponents/SweetAlert'

const ToneRows = ({ curElem, fetchTone, toneData, setToneData, index }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })


    const [show, setShow] = useState(false)
    const [name, setName] = useState("")
    const [loader, setLoader] = useState({
        add: false
    })

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            id: curElem.id,
            name: name,
            cId: curElem.cId
        }
        setLoader({
            ...loader,
            add: true
        })
        dispatch(onSubmitAddTone(data, fetchTone, handleClose,false, loader, setLoader))

    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-tone", data, toneData, setToneData, setSweet))
    }


    useEffect(() => {
        setName(curElem.toneText)
    }, [curElem])

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.toneText}</td>
            <td>
                {+auth.user.isClientAccount === 1 ? null :
                    <>
                        <span
                            onClick={handleShow}
                        >
                            <MdEdit title='Edit' style={{ color: "#616161", cursor: "pointer" }} />
                        </span>
                        <span className='ms-2'
                            onClick={onDelete}
                        >
                            <FaTrash title='Delete' style={{ color: "#616161", cursor: "pointer" }} />
                        </span>
                    </>}
            </td>

            <Modal className="theme-modal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className="connect-modal">
                        <h4>Update Tone</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <div className="input-wrap">
                                    <label htmlFor="">Enter Tone Name</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder='Enter Tone Name Here'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="group-btn">
                            <div className="row">
                                <div className="col-6">
                                    <button className="theme-btn full" type='submit'><span>{loader.add ? <>Updating <FaSpinner className="spin" /></> : "Update"}</span></button>
                                </div>
                                <div className="col-6">
                                    <button className="theme-btn full bdr" type='button' onClick={handleClose}><span>Cancel</span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br><br> You want to delete this tone?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </tr>
    )
}

export default ToneRows