import produce from "immer"
const initialState = {
    data: {
        cId: "",
        sId: "",
        cloneProfile: {
            name: "hello",
            profile: "",
            handle: ""
        },
        cloneBio: {
            headLine: "",
            description: "",
            topics: "",
            organizations: "",
            customTopicArray: [],
            selectedTopic: []
        }
    }
}

export const CloneSettingReducer = (state = initialState, action) => {
    switch (action.type) {

        case "ADD_CLONE_SETTING_DATA":
            return produce(state, (draft) => {
                let obj = { ...action.payload }
                if (!obj.topics) {
                    obj.topics = []
                }
                if (!obj.purpose) {
                    obj.purpose = []
                }
                draft.data = obj
            })

        case "CHANGE_CLONE_SETTING_PROFILE":
            return produce(state, (draft) => {
                draft.data[action.payload.name] = action.payload.value
            })

        case "CHANGE_CLONE_NOTIFICATION":
            return produce(state, (draft) => {
                draft.data.notification[action.payload.name].email = action.payload.value
            })

        case "CHANGE_CLONE_NOTIFICATION_TOGGLE":
            return produce(state, (draft) => {
                draft.data.notification[action.payload.name].enable = action.payload.check
            })

        case "HANDLE_UPDATE_CUSTOM_WARNING":
            return produce(state, (draft) => {
                draft.data.customWarning.warningText = action.payload.value
            })

        case "HANDLE_UPDATE_CUSTOM_WARNING_TOGGLE":
            return produce(state, (draft) => {
                draft.data.customWarning.enable = action.payload.check
            })

        case "CHANGE_CLONE_SETTING_BIO":
            return produce(state, (draft) => {
                draft.data[action.payload.name] = action.payload.value
            })

        case "HANDLE_UPDATE_SETTING":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_CHANGE_MESSAGE":
            return produce(state, (draft) => {
                draft.data.defaultMessage = {
                    ...draft.data.defaultMessage,
                    [action.payload.name]: action.payload.value
                }
            })

        case "ON_CHANGE_MONETIZATION":
            return produce(state, (draft) => {
                draft.data.monetization = {
                    ...draft.data.monetization,
                    [action.payload.name]: action.payload.value
                }
            })


        default:
            return state
    }
}
