import React from 'react';
import ConnectWhatsapp from './ConnectWhatsapp';
import ConnectReel from './ConnectReel';
import { useSelector } from 'react-redux';

const Integrations = () => {

    const socialData = useSelector(state => state.social);

    return (
        <div>
            <div className="settings_wrap ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Integrations</h2>
                        </div>
                    </div>
                    <div className="integrations_body">
                        <div className="col-xl-7 col-12">

                            <div className="all_app_wrap">
                                <h3 className='py-4'>All Apps</h3>

                                <ConnectReel
                                    name="Telegram"
                                    type="telegram"
                                    data={socialData.telegram}
                                />

                                <ConnectReel
                                    name="Twilio"
                                    type="twilio"
                                    data={socialData.twilio}
                                />

                                <ConnectReel
                                    name="Stripe"
                                    type="stripe"
                                    data={socialData.stripe}
                                />

                                <ConnectReel
                                    name="Paypal"
                                    type="paypal"
                                    data={socialData.paypal}
                                />

                                <ConnectReel
                                    name="Autoresponder"
                                    type="autoresponder"
                                    data={socialData.autoResponder}
                                />

                                <ConnectReel
                                    name="Slack"
                                    type="slack"
                                    data={socialData.slack}
                                />
                                <ConnectReel
                                    name="Discord"
                                    type="discord"
                                    data={socialData.discord}
                                />

                                <ConnectWhatsapp
                                    name="Whatsapp"
                                    type="whatsapp"
                                    isGroup={false}
                                    data={socialData.whatsapp}
                                />

                                <ConnectWhatsapp
                                    name="Messenger"
                                    type="messenger"
                                    isGroup={false}
                                    data={socialData.messenger}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Integrations
