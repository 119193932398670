import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { connectSocial } from '../../../../../Redux/Actions/SocialActions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const ConnectModal = (props) => {
    const FormDataJson = require('form-data-json-convert')
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const { cId } = queryString.parse(location.search)
    const [appDetails, setAppDetails] = useState({
        cId: cId,
        user_id: auth.user.id,
        app_name: props.type
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setAppDetails({
            ...appDetails,
            [name]: value
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let url = "twilio"
        let data = { ...appDetails }
        if (props.type === "telegram") {
            url = "telegram-connect"
        } else if (props.type === "stripe") {
            url = "stripe-connect"
        } else if (props.type === "autoresponder") {
            url = "connect-auto"

            let element = document.getElementById("auto-form")
            let form = element.getElementsByTagName("form")
            let formData = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            formData.postUrl = form[0].action
            data = {
                ...data,
                name: appDetails.name,
                code: data,
                appName: props.type
            }
        }
        dispatch(connectSocial(url, data, props.name, props.handleClose, setLoader))
    }



    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <form onSubmit={onFormSubmit}>
                    <span className="modal-close p-2 modleCloseIcon" onClick={props.handleClose}><AiOutlineClose /></span>
                    <div className="connect-modal">
                        <h2>Connect {props.name}</h2>
                        {props.type === "telegram" ?
                            <>
                                <div className="input-wrap">
                                    <label htmlFor="">Bot Token</label>
                                    <div className="input-outer">
                                        <input
                                            className="common-inp"
                                            type="text"
                                            placeholder="Enter bot token"
                                            onChange={handleChange}
                                            name='token'
                                            required
                                        />
                                    </div>
                                </div>
                            </>
                            : props.type === "twilio" ?
                                <>
                                    <div className="input-wrap">
                                        <label htmlFor="">Account SID</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter account sid"
                                                name='sId'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">Auth Token</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter auth token"
                                                onChange={handleChange}
                                                name='token'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">From Number</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter from number"
                                                onChange={handleChange}
                                                name='fromNumber'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">To Number (Need for testing)</label>
                                        <div className="input-outer">
                                            <input
                                                className="common-inp"
                                                type="text"
                                                placeholder="Enter to number"
                                                onChange={handleChange}
                                                name='toNumber'
                                                required
                                            />
                                        </div>
                                    </div>
                                </>
                                : props.type === "stripe" ?
                                    <>
                                        <div className="input-wrap">
                                            <label htmlFor="">Secret Key</label>
                                            <div className="input-outer">
                                                <input
                                                    className="common-inp"
                                                    type="text"
                                                    placeholder="Enter secret key"
                                                    name='secretKey'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : props.type === "autoresponder" ?
                                        <>
                                            <div className="input-wrap">
                                                <label htmlFor="">Name</label>
                                                <div className="input-outer">
                                                    <input
                                                        className="common-inp"
                                                        type="name"
                                                        placeholder="Enter Name Here"
                                                        name='name'
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="input-wrap">
                                                <label htmlFor="">Opt-in Code</label>
                                                <div className="input-outer">
                                                    <textarea
                                                        className="common-inp"
                                                        type="text"
                                                        placeholder="Enter Code Here"
                                                        name='code'
                                                        onChange={handleChange}
                                                        required
                                                        rows={8}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                        : ""
                        }
                    </div>
                    <div className="group-btn">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn full bdr" style={{ cursor: "pointer" }} type='button' onClick={props.handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" type='submit' style={{ cursor: "pointer" }}><span>{loader ? <>Submitting <FaSpinner className="spin" /></> : "Submit"}</span></button>
                            </div>
                        </div>
                    </div>
                </form>
                <div id='auto-form' style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: appDetails.code }} />
            </Modal.Body>
        </Modal >
    )
}

export default ConnectModal