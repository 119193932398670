import React, { useEffect, useState } from 'react'
import { fetchData, onDeleteCampaign } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { RxCross2 } from 'react-icons/rx';
import { onCreateTopic } from '../../../Redux/Actions/CloneSettingAction';
import { FaPlusCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import SweetAlert from '../../CommonComponents/SweetAlert';
import { themeColor } from '../../../Global/Global';
import { MdOutlineCancel } from 'react-icons/md';

const Topics = ({ handleUpdate, data, setData, loader, setLoader }) => {
    const dispatch = useDispatch()
    const [topics, setTopics] = useState([])
    const location = useLocation()

    const { cId } = queryString.parse(location.search)

    const [isEditable, setIsEditable] = useState(false);
    const [fetchLoader, setFetchLoader] = useState({
        fetch: false
    });
    const [customTopics, setCustomTopics] = useState([]);

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false,
        id: false
    })

    const handleAdd = (name) => {
        if (data.topics.includes(name)) {
            let newTopics = data.topics.filter((curElem) => {
                return curElem !== name
            })
            setData((draft) => {
                draft.topics = newTopics
            })
        }
        else {
            if (data.topics.length >= 5) {
                dispatch(setAlert("You can add maximum 5 topics", "danger"))
            }
            else {
                setData((draft) => {
                    draft.topics.push(name)
                })
            }
        }
    }

    const handleCreateTopic = (e) => {
        const { value } = e.target;
        if (value && isEditable === true) {
            let data = {
                cId: cId,
                name: value
            }
            if ((topics.findIndex(({ name }) => name.toLowerCase() === data.name.toLowerCase()) === -1) && customTopics.findIndex(({ name }) => name.toLowerCase() === data.name.toLowerCase()) === -1) {
                setLoader({ ...loader, create: true })
                dispatch(onCreateTopic(data, fetchCustomTopic, loader, setLoader))
                setIsEditable(false)
            } else {
                dispatch(setAlert("This topic is already exists please provide a different one!", "danger"))
            }
        }
    };

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false,
            id: false
        })
    }

    const performDelete = () => {
        let data = {
            id: sweet.id,
            cId: cId
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-custom-topic", data, customTopics, setCustomTopics, setSweet))
    }

    const fetchTopics = () => {
        setFetchLoader({ ...fetchLoader, fetch: true })
        dispatch(fetchData("fetch-topics", {}, setTopics, fetchLoader, setFetchLoader))
    }

    const fetchCustomTopic = () => {
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-custom-topic", data, setCustomTopics))
    }

    useEffect(() => {
        if (cId) {
            fetchCustomTopic()
            fetchTopics()
        }
    }, [cId])

    return (
        <div>
            <h3>Select topics for your clone</h3>
            <p>Recommends picking particular topics or passions that you want your clone to be educated or proficient in.</p>
            <div className='topic-list'>
                <ul>
                    {topics.length > 0 ?
                        topics.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <div className={`topic-list-single ${data.topics.includes(curElem.name) ? 'active-topic' : ""}`} onClick={() => handleAdd(curElem.name)}>
                                        <div className='topic-list-cont text-capitalize '><i className={`fa-solid ${curElem.icon}`}></i>{curElem.name}</div>
                                    </div>
                                </li>
                            )
                        })

                        :
                        fetchLoader.fetch ?
                            <li className='text-center' style={{ width: "100%" }}>
                                <i className='fa fa-spin fa-spinner fetch-loader' />
                            </li> : ""
                    }



                    {customTopics.length > 0 ?
                        customTopics.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <div className="" style={{ position: "relative" }}>
                                        <div className={`topic-list-single text-center d-flex justify-content-between align-items-center ${data.topics.includes(curElem.name) ? "active-topic" : ""}`} onClick={() => handleAdd(curElem.name)}>{curElem.name} <MdOutlineCancel style={{ fontSize: 17, cursor: "pointer", marginLeft: "5px" }} onClick={() => onDelete(curElem.id)} /></div>

                                        <SweetAlert
                                            show={sweet.enable}
                                            message={"<b>Are you sure?</b> <br> <br> You want to delete this topic?"}
                                            confirmButtonColor={""}
                                            cancelButtonColor={""}
                                            confirmButtonName={sweet.confirmButtonName}
                                            alertLoader={sweet.loader}
                                            cancelButtonName={"Cancel"}
                                            handleClose={onCancelDelete}
                                            performDelete={performDelete}
                                        />
                                    </div>
                                </li>
                            )
                        }) : ""
                    }
                    {loader.create ?
                        <div className='mt-2'>
                            <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "13px" }} />
                        </div>
                        : ""
                    }


                    {/* <li>
                        <div className='topic-list-single'>
                            <div className='topic-list-cont'><BsBook /> Education</div>
                            <input type="checkbox" name="" id="" />
                        </div>
                    </li> */}
                </ul>
                <div className="setting_btn text-start mt-4">
                    {isEditable ?
                        <div style={{ position: "relative" }}>
                            <input
                                type="text"
                                name='content'
                                placeholder='Enter Topic'
                                className='common-inp'
                                onKeyUp={(event) =>
                                    event.key === "Enter" ? handleCreateTopic(event) : null
                                }
                            />
                            <RxCross2 onClick={() => setIsEditable(false)} className='crossInput' size={20} />
                        </div>
                        :
                        <button type='button' className='off-btn me-0' style={{ color: "#71717a" }} onClick={() => setIsEditable(true)}><span> <FaPlusCircle className="me-2" />Add your own</span></button>
                    }
                </div>
            </div>
            <div className='input-wrap'>
                <button className='theme-btn full' onClick={() => handleUpdate(1)}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
            </div>
        </div>
    )
}

export default Topics