import React, { useEffect, useState } from 'react'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { MdEdit, MdOutlineCheckCircleOutline, MdOutlineDataSaverOn } from 'react-icons/md'
import { themeColor } from '../../../Global/Global'
import { onDeletePurpose, onUpdateCustomePurpose } from '../../../Redux/Actions/CloneActions'
import { useDispatch } from 'react-redux'
import { RxCross2 } from "react-icons/rx";
import SweetAlert from '../../CommonComponents/SweetAlert'
const CustomePurposeRows = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [activeEdit, setActiveEdit] = useState({
        active: false,
        text: ""
    })

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleUpdate = () => {
        let data = {
            id: props.curElem.id,
            cId: props.data.id,
            purpose: activeEdit.text
        }
        setLoader(true)
        dispatch(onUpdateCustomePurpose(data, props.fetchCustomePurpose, setActiveEdit, setLoader))
    }

    const handleEditClick = () => {
        props.setIsActive(true)
        setActiveEdit({
            ...activeEdit,
            active: true,
            text: props.curElem.purpose
        })
    }

    const handleEdit = (e) => {
        setActiveEdit({
            ...activeEdit,
            text: e.target.value
        })
    }



    const handleDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: props.curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeletePurpose(data, props.fetchCustomePurpose, setSweet))
    }
    useEffect(() => {
        if (!props.isActive) {
            setActiveEdit({
                text: "",
                active: false
            })
        }
    }, [props.isActive])


    return (
        <li className='d-flex justify-content-between'>
            <div style={{ width: "86%", marginRight: 5 }} className='d-flex align-items-center' >
                <label className='check-select'>
                    <input type="checkbox" checked={props.data.purpose.includes(props.curElem.purpose)} onChange={(e) => props.handleAdd(e, props.curElem.purpose)} />
                    <span className='check-select-mark'></span>
                </label>
                {activeEdit.active ?
                    <input
                        value={activeEdit.text}
                        onChange={handleEdit}
                        style={{ width: "100%", border: `1px solid ${themeColor}`, padding: 10, borderRadius: 15, marginLeft: "14px" }}
                    /> :
                    <p>{props.curElem.purpose}</p>
                }

            </div>
            <div style={{ fontSize: 19, width: "10%", position: "relative", zIndex: "1" }}>
                {activeEdit.active ?
                    <>
                        {loader ? <i className='fa fa-spinner fa-spin me-2' style={{ fontSize: "18px", color: themeColor }} /> :
                            <MdOutlineCheckCircleOutline
                                className='me-2' title='Update' cursor={"pointer"} onClick={handleUpdate}
                                color={themeColor}
                                style={{ fontSize: "23px" }}
                            />
                        }
                        <RxCross2 title='Cancel' cursor={"pointer"} onClick={() => setActiveEdit({
                            text: "",
                            active: false
                        })}
                            color={themeColor}
                            style={{ fontSize: "22px" }}
                        />
                    </>
                    :
                    <>
                        <MdEdit className='me-2' title='Edit' cursor={"pointer"} onClick={handleEditClick} color={themeColor} />
                        <BsFillTrash3Fill title='Delete' cursor={"pointer"} color={themeColor} onClick={handleDelete} />
                    </>
                }

            </div>

            <SweetAlert
                show={sweet.enable}
                message={"<b>Are you sure?</b> <br><br> You want to delete this goal?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </li>
    )
}

export default CustomePurposeRows