import React from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { onEditClient } from '../../../Redux/Actions/AccountManageAction'
import { useEffect } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { setAlert } from '../../../Redux/Actions/AlertActions'

const EditMemberModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState({
        id: "",
        name: "",
        email: "",
        password: ""
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetails({
            ...details,
            [name]: value
        })
    }

    const handleSubmit = () => {
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (details.password) {
            if (pattern.test(details.password)) {
                setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: false })
                setLoader(true);
                dispatch(onEditClient(details, props.fetchMembers, props.handleClose, setLoader))
            } else {
                // dispatch(setAlert("Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", "danger"));
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            dispatch(onEditClient(details, props.fetchMembers, props.handleClose, setLoader))
        }
    }


    useEffect(() => {
        setDetails({
            ...details,
            name: props.user.name,
            email: props.user.email,
            id: props.user.id
        })

        return () => {
            setDetails({
                ...details,
                id: "",
                name: "",
                email: "",
                password: ""
            })
        }
    }, [props])

    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="connect-modal">
                    <h4>Edit Team Member</h4>
                </div>
                <div className='row'>
                    <div className='col-md-6 '>
                        <div className="input-wrap">
                            <label htmlFor="">Account Type</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    value={props.type}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 '>
                        <div className="input-wrap">
                            <label htmlFor="">Account Name</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    name="name"
                                    placeholder="Enter Name"
                                    value={details.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 '>
                        <div className="input-wrap">
                            <label htmlFor="">Email</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    name='email'
                                    value={details.email}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="input-wrap">
                            <label htmlFor="">Password</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="password"
                                    value={details.password}
                                    name='password'
                                    onChange={handleChange}

                                />
                                {passwordMsg.validation ?

                                    <p style={{ color: passwordMsg.validation ? "red" : "green", fontSize: "12px" }}>{passwordMsg.msg}</p>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div className="group-btn">
                    <div className="row">
                        <div className="col-6">
                            <button className="theme-btn full bdr" onClick={props.handleClose}><span>Cancel</span></button>
                        </div>
                        <div className="col-6">
                            <button className="theme-btn full" onClick={handleSubmit}><span>{loader ? <>Updating <FaSpinner className="spin" /></> : "Update"}</span></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditMemberModal