import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { saveFacebook } from '../../../../../Redux/Actions/SocialActions'
import { FaSpinner } from 'react-icons/fa'

const GroupsModal = (props) => {

    const dispatch = useDispatch();
    const { details, setDetails, groups } = props
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = () => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        dispatch(saveFacebook(data, setLoader));
        setDetails({ ...details, openPopup: false });
    };

    return (
        <Modal className='theme-modal xl' show={details.openPopup} onHide={closeConnectModel} centered scrollable >
            <Modal.Body>
                <div className='info-tab'>
                    <span className="modal-close" onClick={closeConnectModel}><AiOutlineClose /></span>
                    <h3>Select an account</h3>
                    <hr />
                    <div className="alert alert-info" role="alert">
                        Please note that, you can post into Facebook Pages and Facebook Groups only if you have the administrator rights for that Page or Group. If a Page or Group of yours is not listed which means you might not have the administrator rights for that Page or Group.
                    </div>
                    <div className='row'>
                        <div className='common-input-wrap'>
                            <select
                                className="inpx"
                                value={dropValue}
                                onChange={handleChange}
                            >
                                <option value="Select an Account">Select An Account</option>
                                <optgroup label="Your Facebook Pages">
                                    {details.pages.map((page) => (
                                        <option
                                            key={page.id}
                                            value={
                                                page.name +
                                                "___CR___" +
                                                page.access_token +
                                                "___CR___page___CR___" +
                                                page.id
                                            }
                                        >
                                            {page.name}
                                        </option>
                                    ))}
                                </optgroup>
                                <optgroup label="Your Facebook Groups">
                                    {groups.map((group) => (
                                        <option
                                            key={group.id}
                                            value={
                                                group.name +
                                                "___CR___" +
                                                details.accessToken +
                                                "___CR___group___CR___" +
                                                group.id
                                            }
                                        >
                                            {group.name}
                                        </option>
                                    ))}
                                </optgroup>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="group-btn">
                    <div className="row">
                        <div className="col-6">
                            <button className="theme-btn full bdr" style={{ cursor: "pointer" }} type='button' onClick={closeConnectModel}><span>Cancel</span></button>
                        </div>
                        <div className="col-6">
                            <button className="theme-btn full" type='button' style={{ cursor: "pointer" }} onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}><span>{loader ? <>{details.buttonText} <FaSpinner className="spin" /> </> : details.buttonText} </span></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default GroupsModal
