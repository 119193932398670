import React, { useState } from 'react'
import uploads from "../../../../../assets/images/uploads.png"
import { AiOutlineClose } from 'react-icons/ai';
import Swal from "sweetalert2"
import { themeColor } from '../../../../../Global/Global';
import { useDispatch } from 'react-redux';
import { onContinueData, onUploadDocs } from '../../../../../Redux/Actions/CloneActions';
import { FaFileAlt, FaVideo, FaImage, FaMusic } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
let mediaType
const FilesModal = (props) => {
    const dispatch = useDispatch()
    const allowItems1 = ["application/pdf", "text/csv", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"]
    const allowItems2 = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4', 'audio/mpeg']
    const [loader, setLoader] = useState({
        continue: false,
        upload: false
    })
    const [percent, setPercent] = useState(0)
    const [fileData, setFileData] = useState({
        name: ""
    })

    const [activeUpload, setActiveUpload] = useState(false)

    const onCompeleteUpload = (obj) => {
        setFileData({
            ...fileData,
            ...obj
        })
    }


    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            if (props.type === "Files") {
                const formData = new FormData()
                formData.append("file", e.target.files[0])
                if (allowItems1.includes(e.target.files[0].type.toLowerCase())) {
                    if (e.target.files[0].size < 300000000) {
                        if (e.target.files[0].type.toLowerCase() === "text/csv") {
                            formData.append("upload_type", "csv")
                            mediaType = "csv"
                        } else {
                            formData.append("upload_type", "docx")
                            if (e.target.files[0].type.toLowerCase() === "application/pdf") {
                                mediaType = "pdf"
                            } else {
                                mediaType = "docs"
                            }
                        }
                        setLoader({
                            ...loader,
                            upload: true
                        })
                        setFileData({
                            ...fileData,
                            name: e.target.files[0].name
                        })
                        setActiveUpload(true)
                        dispatch(onUploadDocs("upload-file", formData, onCompeleteUpload, loader, setLoader, setPercent))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Max allowed size is 300MB!',
                            confirmButtonColor: themeColor
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have selected invalid file type!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                if (allowItems2.includes(e.target.files[0].type.toLowerCase())) {
                    const formData = new FormData()
                    formData.append("file", e.target.files[0])
                    if (e.target.files[0].size <= 300000000) {
                        if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                            formData.append("upload_type", "images")
                            mediaType = "image"
                        } else if (e.target.files[0].type === 'video/mp4') {
                            formData.append("upload_type", "video")
                            mediaType = "mp4"
                        } else {
                            formData.append("upload_type", "music")
                            mediaType = "mp3"
                        }
                        setLoader({
                            ...loader,
                            upload: true
                        })
                        setFileData({
                            ...fileData,
                            name: e.target.files[0].name
                        })

                        setActiveUpload(true)
                        dispatch(onUploadDocs("upload-file", formData, onCompeleteUpload, loader, setLoader, setPercent))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Max allowed size is 300MB!',
                            confirmButtonColor: themeColor
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have selected invalid file type!',
                        confirmButtonColor: themeColor
                    })
                }
            }

        }
    }


    const handleContinue = () => {
        let data = {
            cId: props.cId,
            url: fileData.path,
            sId: props.sId,
            sourceId: props.type === "Files" ? 4 : 5,
            source: props.type,
            sourceType: mediaType
        }
        let ep = "text-extract"
        if (props.type !== "Files") {
            ep = "media-extract"
        }
        setLoader({
            ...loader,
            continue: true
        })
        dispatch(onContinueData(ep, data, props.fetchContent, props.handleClose, loader, setLoader))
    }

    const handleCancel = () => {
        setActiveUpload(false)
        setFileData({
            ...fileData,
            name: ""
        })
    }



    return (
        <>
            <div className="content_modal">
                <div className='d-flex justify-content-between align-items-center mt-3' >
                    <h2 className=''>{props.type}</h2>
                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className='upload_wrap mt-3'>
                                <div className='upload_content_wrap'>
                                    <img src={uploads} alt="" style={{ height: "98px" }} />
                                    {loader.upload ? null :
                                        <input
                                            type="file"
                                            accept={props.type === "Files" ? ".doc, .docx, .pdf, .csv" : ".mp4, .mp3, .jpg, .jpeg, .png"}
                                            onChange={uploadFile}
                                        />
                                    }
                                    <h3 >Upload {props.type}</h3>
                                    <p className="text-p3 text-center"><span className="text-brand">Click to browse</span> or drag and drop</p>
                                    {loader.upload ?
                                        <div className="progress mt-3">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: `linear-gradient(to left, ${themeColor}, ${themeColor} 100%)` }} aria-valuenow={`${percent}`}
                                                aria-valuemin="0" aria-valuemax="100">{percent}%

                                            </div>
                                        </div>
                                        : ""}
                                </div>

                            </div>
                        </div>
                    </div>

                    {activeUpload ?
                        <div className='row mt-4 ps-2'>
                            <div className='col-12'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='d-flex  justify-content-center'>
                                        <span className='file-uploaded-ui'>
                                            {loader.upload ? <i className='fa fa-spin fa-spinner' /> :
                                                fileData.type === "video" ?
                                                    <FaVideo /> :
                                                    fileData.type === "images" ?
                                                        <FaImage />
                                                        :
                                                        fileData.type === "music" ?
                                                            <FaMusic />
                                                            :
                                                            <FaFileAlt />
                                            }
                                        </span>
                                        <p style={{ fontSize: 14, fontWeight: 500 }}>{fileData.name}</p>
                                    </div>
                                    <div>
                                        {loader.upload ? null :
                                            <MdOutlineCancel color={themeColor} size={20} onClick={handleCancel} cursor={"pointer"} title='Cancel' />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> : null}


                    {/* <div className="row mt-4">
                        <div className="col-6">
                            <div className='upload_content_wrap mb-3' style={{ padding: "10px" }}>
                                <div className="d-flex align-items-center">
                                    <IoLogoDropbox className='me-2' />
                                    <span style={{ fontSize: "13px", fontWeight: "500", color: "#71717a" }}>Choose from Dropbox</span>
                                </div>

                            </div>
                        </div>
                        <div className="col-6">
                            <div className='upload_content_wrap mb-3' style={{ padding: "10px" }}>
                                <div className="d-flex align-items-center">
                                    <FaGoogleDrive className='me-2' />
                                    <span style={{ fontSize: "13px", fontWeight: "500", color: "#71717a" }}>Choose from Google Drive</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className='upload_content_wrap mb-3' style={{ padding: "10px" }}>
                                <div className="d-flex align-items-center">
                                    <SiMicrosoftonedrive className='me-2' />
                                    <span style={{ fontSize: "13px", fontWeight: "500", color: "#71717a" }}>Choose from OneDrive</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className='upload_content_wrap mb-3' style={{ padding: "10px" }}>
                                <div className="d-flex align-items-center">
                                    <SiBox className='me-2' />
                                    <span style={{ fontSize: "13px", fontWeight: "500", color: "#71717a" }}>Choose from Box</span>
                                </div>
                            </div>
                        </div>

                    </div> */}
                </div>
            </div>
            <div className="col-12 text-end mt-4">
                <button className='theme-btn me-3 text-p4' onClick={handleContinue}><span>Continue {loader.continue ? <i className='fa fa-spin fa-spinner' /> : ""}</span></button>
            </div>
        </>
    )
}

export default FilesModal